import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../../app-routes'
import { AppTableContainer } from '../../components/AppTableContainer'
import { IUser } from '../../data-types'

interface Props {
  users: IUser[]
  loading?: boolean
}

export const UsersTable: React.FC<Props> = ({ loading, users }) => {
  const history = useHistory()

  return (
    <AppTableContainer
      label="Users"
      header={['ID', 'Email', 'Super admin']}
      loading={loading}
      data={users}
      renderRow={renderRow}
      getItemKey={u => u.id}
      empty={!loading && !users.length}
      onRowClick={u => history.push(AppRoutes.USER.getRoute(u.id))}
    />
  )
}

const renderRow = (c: IUser) => {
  return (
    <>
      <td>{c.id}</td>
      <td>{c.email}</td>
      <td>{c.superAdmin.toString()}</td>
    </>
  )
}
