import { AppButton } from '../components/AppButton'
import {
  AppForm,
  AppFormProps, FormBooleanField,
  FormButton,
  FormCheckbox,
  FormDateTimeField, FormFileInput,
  FormInputField,
  FormSelectField
} from '../components/Form'

import { useZodForm } from '../hooks/use-zod'
import { updateCouponFormSchema } from './schemas'
import { AddonsWithVariants, CouponCreateDataResponse, CouponValueLabel, ICoupon, IMarket, IMarketAmount } from '../data-types'

const getFormattedMarkets = (chosenMarkets: string[] | undefined, allMarkets: IMarket[]): IMarket[] => {
  if (chosenMarkets === undefined) {
    return allMarkets
  }

  const isMarket = (market: IMarket | undefined): market is IMarket => {
    return !!market
  }

  return chosenMarkets.map(chosenMarket => allMarkets.find(market => market.id === chosenMarket)).filter(isMarket);
}

const getAddonVariants = (addonGroup: string, variants: CouponValueLabel[]) => {
  const addonGroupVariants = variants.filter(a => a.value.includes(addonGroup)) ?? []

  if (addonGroupVariants.length > 1) {
    return [
      { value: 'all', label: 'All' },
      ...addonGroupVariants
    ]
  }

  return []
}

const getChoosedAddons = (createData: CouponCreateDataResponse, addonIds?: string[]) => {
  if (!addonIds) return
  // Find the corresponding addon variant options from the array of variants based on addonId
  const selectedAddons = addonIds.map(addonId => createData?.addonGroups.find(ag => ag.value === addonId))
  let listOfItems: (AddonsWithVariants | undefined)[] = []
  selectedAddons?.forEach((addon) => {
    if (addon) {
      let variants = createData?.variants.filter((variant) => variant.value?.includes(addon.value))
      listOfItems.push({
        addon: { ...addon },
        variants,
        numberOfVariants: variants?.length || 0
      })
    } else {
      listOfItems.push(undefined)
    }
  })
  return listOfItems;
}

const updateMarketAmounts = (markets: any, marketAmounts?: IMarketAmount[]) => {
  return markets.map((m: string) => {
    const existingMarketAmount = marketAmounts?.find(ma => ma.marketId === m);

    if (existingMarketAmount) {
        return existingMarketAmount
    }

    return {
      marketId: m,
      amount: 0
    }
  })
}

const couponInUse = (coupon: ICoupon) => coupon.redeemCount > 0

// setting the fields manually to undefined as disabling the inputs does not do it automatically
// It is fixed in a later version https://github.com/react-hook-form/react-hook-form/issues/6690
const formatSubmit = (form: any, data: ICoupon) => {
  const {markets, discountAmounts, discountType, durationType, applyOn, applyOnAddons, applyOnAddonVariants, discountPercentage, minimumAmount, ...rest} = form
  return {
    ...rest,
    name: undefined,
    markets: !couponInUse(data) ? markets : undefined,
    discountAmounts: data?.discountType.value === 'fixed_amount' && !couponInUse(data) ? (discountAmounts || []).map((da: IMarketAmount) => {
      return {
        marketId: da.marketId,
        amount: Math.round(da.amount * 100)
      }
    }) : undefined,
    discountType: !couponInUse(data) ? discountType : undefined,
    discountPercentage: !couponInUse(data) ? discountPercentage : undefined,
    durationType: !couponInUse(data) ? durationType : undefined,
    applyOn: !couponInUse(data) ? applyOn : undefined,
    applyOnAddons: !couponInUse(data) ? applyOnAddons : undefined,
    applyOnAddonVariants: !couponInUse(data) ? applyOnAddonVariants : undefined,
    minimumAmount: (minimumAmount || []).map((ma: IMarketAmount) => {
      return {
        marketId: ma.marketId,
        amount: Math.round(ma.amount * 100)
      }
    })
  }
}

interface UpdateCouponFormProps<T> extends AppFormProps<T> {
  onCancel: () => void
  data: ICoupon
  createData: CouponCreateDataResponse
}

export const UpdateCouponForm: React.FC<UpdateCouponFormProps<any>> = ({ onSubmit, onCancel, data, createData }) => {
  const form = useZodForm(updateCouponFormSchema, {
    defaultValues: {
      name: data?.name,
      fullName: data?.fullName,
      team: data?.team ?? '',
      invoiceName: data?.invoiceName ?? '',
      markets: data.markets.filter(n => n),
      discountType: data?.discountType.value,
      discountPercentage: data?.discountType.value === 'percentage' ? data?.discountPercentage : undefined,
      discountAmounts: (data.discountAmounts || []).map(da => {
        return {
          marketId: da.marketId,
          amount: Math.round(da.amount / 100)
        }
      }),
      freeProductAddonGroup: data.freeProductAddonGroup,
      freeAddonVariant: data.freeAddonVariant,
      validFrom: data?.validFrom ? new Date(data?.validFrom).toJSON().slice(0, -1) : undefined,
      validUntil: data?.validUntil ? new Date(data?.validUntil).toJSON().slice(0, -1) : undefined,
      maxRedemptions: data?.maxRedemptions ? data?.maxRedemptions.toString() : undefined,
      maxRedemptionsPerUser: data?.maxRedemptionsPerUser ? data?.maxRedemptionsPerUser.toString() : undefined,
      subscriptionRequired: data?.subscriptionRequired,
      canBeUsedBy: data?.canBeUsedBy?.value ?? createData?.defaultCanBeUsedByOption,
      applyOn: data?.applyOn.value ?? '',
      applyOnAddons: data.applyOnAddons?.length ? data.applyOnAddons : [],
      applyOnAddonVariants: data.applyOnAddonVariants?.length ? data.applyOnAddonVariants : [],
      requiredAddon: data.requiredAddon,
      requiredAddonVariant: data.requiredAddonVariant,
      durationType: data.durationType.value,
      period: data.period ?? undefined,
      periodUnit: data.periodUnit ?? undefined,
      minimumAmount: (data.minimumAmount || []).map(ma => {
        return {
          marketId: ma.marketId,
          amount: Math.round(ma.amount / 100)
        }
      }),
    },
  });

  const discountType = form.getValues('discountType')
  const chosenMarkets = form.watch('markets')
  const freeProductAddonGroup = form.watch('freeProductAddonGroup')
  const applyOn = form.watch('applyOn')
  const addonIds = form.watch('applyOnAddons')
  const requiredAddon = form.watch('requiredAddon')
  const durationType = form.watch('durationType')

  const addonsVariants = getChoosedAddons(createData, addonIds) || []

  return (
    <AppForm
      onSubmit={form => onSubmit(formatSubmit(form, data))}
      form={form}
      testId='update-coupon-form'
    >
      <p>Fields that cannot be updated after the coupon is created or redeemed are disabled.</p>
      <div className="box">
        <div className="block">
          <FormInputField
            label="Coupon code*"
            name="name" 
            text={
              <>Coupon code needs to be upper-cased without spacing.</>
            }
            disabled={true}
            testId='name'
          />
        </div>
        <div className="block">
          <FormInputField
            label="Influencers name"
            name="fullName"
          />
        </div>
        <div className="block">
          <FormSelectField
            label="Team*"
            name="team"
            options={createData?.team ?? []}
            placeholder="Select team"
          />
        </div>
        <div className="block">
          <FormInputField
            label="Purpose*"
            name="invoiceName"
          />
        </div>
        <div className="block">
          <label className="label">Markets</label>
          {createData?.markets.map(market => (
            <FormCheckbox
              key={market.id}
              label={market.name}
              name="markets"
              value={market.id}
              testId={`market-${market.id}`}
              disabled={couponInUse(data)}
              onChange={(e) => {
                const markets = form.getValues('markets')
                const currentDiscountAmounts = form.getValues('discountAmounts')
                const currentMinimumAmounts = form.getValues('minimumAmount')

                const updatedDiscountAmounts = updateMarketAmounts(markets, currentDiscountAmounts || [])
                form.setValue('discountAmounts', updatedDiscountAmounts)

                const updatedMinimumAmounts = updateMarketAmounts(markets, currentMinimumAmounts)
                form.setValue('minimumAmount', updatedMinimumAmounts)
              }}
            />
          ))}
        </div>
      </div>
      <div className="box">
        <div className="block">
          <FormSelectField
            label="Coupon type*"
            name="discountType"
            options={createData?.discountTypes ?? []}
            placeholder="Select coupon type"
            disabled={true}
          />
        </div>
        {discountType === 'percentage' && (
          <FormInputField
            type="number"
            name="discountPercentage"
            label="Discount percentage*"
            disabled={couponInUse(data)}
            setValueAs={v => v === '' || v === null ? null : parseInt(v, 10)}
            min={0}
          />
        )}
        {discountType === 'fixed_amount' && (
          <div className="block">
            <label className="label">Discount amounts*</label>
            {getFormattedMarkets(chosenMarkets, createData?.markets ?? []).map((m,i) => (
              <div
                key={`discountAmounts.${m.id}`}
              >
              <FormInputField
                label={m.name}
                text={m.currencyCode}
                name={`discountAmounts.${i}.amount`}
                type="number"
                step={0.01}
                min={0}
                testId={`discount-amount-amount-${m.id}`}
                valueAsNumber={true}
                disabled={couponInUse(data)}
              />
              <FormInputField
                name={`discountAmounts.${i}.marketId`}
                type="hidden"
                testId={`discount-amount-market-id-${m.id}`}
                defaultValue={m.id}
                disabled={couponInUse(data)}
              />
              </div>
            ))}
          </div>
        )}
        {discountType === 'free_product' && (
          <div className="block">
            <FormSelectField
              label="Free product*"
              name="freeProductAddonGroup"
              options={createData?.addonGroups ?? []}
              placeholder="Select free product"
              disabled={couponInUse(data)}
            />
          </div>
        )}
        {discountType === 'free_product' && freeProductAddonGroup && getAddonVariants(freeProductAddonGroup, createData.variants).length > 1 && (
          <div className="block">
            <FormSelectField
              label="Free Variant*"
              name="freeAddonVariant"
              options={getAddonVariants(freeProductAddonGroup, createData.variants)}
              placeholder="Select product variant"
            />
          </div>
        )}
      </div>
      {discountType !== 'free_product' && (
        <>
          <div className="box">
            <div className="field block">
              <FormSelectField
                label="Apply coupon*"
                name="applyOn"
                options={createData?.applyOn ?? []}
                placeholder="Select coupon apply on"
                testId='apply-on'
                disabled={couponInUse(data)}
                onChange={e => {
                  form.setValue('applyOn', e.target.value)
                  if (e.target.value === 'invoice_amount') {
                    form.setValue('applyOnAddons', [])
                    form.setValue('applyOnAddonVariants', [])
                  }
                }}
              />
            </div>
            {applyOn === 'each_specified_item' && (
              createData?.addonGroups.map((a, i) => (
                <FormCheckbox
                  key={a.value}
                  label={a.label}
                  name={`applyOnAddons[]`}
                  value={a.value}
                  testId={`addon-${a.value}`}
                  disabled={couponInUse(data)}
                />
              ))
            )}
          </div>
          <>
            {addonsVariants.length > 0 && (
              addonsVariants.filter(a => !!a).map((addon, i) => {
                if ((addon?.numberOfVariants || 0) > 1) {
                  return (applyOn === 'each_specified_item' && (
                    <div className="box" key={addon?.addon.value}>
                      <label className="label">{addon?.addon?.label}</label>
                      {(addon?.variants || []).map((a) => (
                        <FormCheckbox
                          key={a.value}
                          label={a.label}
                          name="applyOnAddonVariants[]"
                          value={a.value}
                          testId={`addon-variant-${a.value}`}
                        />
                      ))}
                    </div>
                  ))
                } else {
                  return ''
                }
              })
            )}
          </>

        </>
      )}
      <div className="box">
        <div className="block">
          <FormSelectField
            label="Required product in cart"
            name="requiredAddon"
            options={createData?.addonGroups ?? []}
            placeholder="Choose addon"
            testId="required-addon"
            onChange={e => {
              form.setValue('requiredAddon', e.target.value)
              form.setValue('requiredAddonVariant', '')
            }}
          />
        </div>
        <div className="block">
          {requiredAddon && getAddonVariants(requiredAddon, createData.variants).length > 1 && (
            <FormSelectField
              label="Required addon variant"
              name="requiredAddonVariant"
              options={getAddonVariants(requiredAddon, createData.variants)}
              placeholder="Select required addon variant"
              testId="required-addon-variant"
            />
          )}
        </div>
      </div>
      {discountType !== 'free_product' && applyOn !== 'each_specified_item' && !requiredAddon && (
        <div className="box">
          <div className="block">
              <FormSelectField
                label="Duration type*"
                name="durationType"
                options={createData?.durationTypes ?? []}
                placeholder="Select duration type"
                text="How long should this coupon be applied to the subscription."
                onChange={e => {
                  form.setValue('durationType', e.target.value)
                  if (e.target.value !== 'limited_period') {
                    form.setValue('period', null)
                    form.setValue('periodUnit', null)
                  }
                }}
              />
          </div>
          {durationType === 'limited_period' && (
            <>
              <div className="block">
                <FormInputField 
                  type="number" 
                  name="period" 
                  label="Period"
                  setValueAs={v => v === '' || v === null ? null : parseInt(v, 10)}
                />
              </div>
              <div className="block">
                <FormSelectField
                  label="Period unit"
                  name="periodUnit"
                  options={createData?.periodUnits ?? []}
                  placeholder="Select period unit"
                  setValueAs={v => v === '' || v === null ? null : v}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className="box">
        <div className="block">
          <FormDateTimeField
            label="Valid from"
            name="validFrom"
            text="Date and time (CEST) upto which the coupon can be applied to new subscriptions."
          />
        </div>
        <div className="block">
          <FormDateTimeField
            label="Valid till*"
            name="validUntil"
            text="Date and time (CEST) upto which the coupon can be applied to new subscriptions."
          />
        </div>
      </div>
      <div className="box">
        <div className="block">
          <FormInputField
            label="Total redemptions"
            name="maxRedemptions"
            type="number"
            text="Maximum number of times this coupon can be redeemed."
            min={1}
          />
        </div>
        <div className="block">
          <FormInputField
            label="Total redemptions per user"
            name="maxRedemptionsPerUser"
            type="number"
            text="Maximum number of times this coupon can be redeemed per user."
            min={1}
          />
        </div>
      </div>
      <div className="box">
        <div className="block">
          <FormBooleanField
            label="Valid for subscription purchases only (If checked, the coupon will not work for one-offs or standalones)."
            name="subscriptionRequired"
          />
        </div>
      </div>
      <div className="box">
        <div className="block">
          <FormSelectField
            label="Can be used by"
            name="canBeUsedBy"
            options={createData?.canBeUsedByOptions ?? []}
          />
        </div>
      </div>
      <div className="box">
        <div className="block">
          <label className="label">Minimum amount required</label>
          {getFormattedMarkets(chosenMarkets, createData?.markets ?? []).map((m,i) => (
              <div
                key={`minimumAmount.${m.id}`}
              >
              <FormInputField
                label={m.name}
                text={m.currencyCode}
                name={`minimumAmount.${i}.amount`}
                type="number"
                step={0.01}
                min={0}
                testId={`minimum-amount-amount-${m.id}`}
                valueAsNumber={true}
                defaultValue={0}
              />
              <FormInputField
                name={`minimumAmount.${i}.marketId`}
                type="hidden"
                testId={`minimum-amount-market-id-${m.id}`}
                defaultValue={m.id}
              />
              </div>
            ))}
        </div>
      </div>
      <div className="box">
        <div className="block">
          <FormFileInput name="file" label="Customer emails that coupons will apply to" accept=".csv" />
        </div>
      </div>

      <div className="d-flex">
        <FormButton>Save</FormButton>
        <div className="ml-2">
          <AppButton variant="light" onClick={onCancel}>
            Cancel
          </AppButton>
        </div>
      </div>
    </AppForm>
  )
}
