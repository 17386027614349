import React, { ReactNode, useState } from 'react'
import { Modal, Toast } from 'react-bootstrap'
import ReactDOM from 'react-dom'

function getRootElement() {
  return document.getElementById('modal-container')!
}

type ToastColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'

export function showToast(title: string, msg: string | ReactNode, severity: ToastColor = 'primary') {
  const rootEl = getRootElement()

  ReactDOM.render(<ToastWrapper title={title} msg={msg} severity={severity} />, rootEl)
}

interface MessageProps {
  title: string
  msg: string | ReactNode
  severity?: ToastColor
}

const ToastWrapper = ({ title, msg, severity }: MessageProps) => {
  const rootEl = getRootElement()
  const [open, setOpen] = useState(true)

  const onClose = () => {
    setOpen(false)
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(rootEl)
    }, 100)
  }

  return (
    <div className="app-toast">
      <Toast show={open} onClose={onClose} autohide bg={severity}>
        <Toast.Header>{title}</Toast.Header>
        <Toast.Body className="text-white">{msg}</Toast.Body>
      </Toast>
    </div>
  )
}

interface ShowDialogParams<T> {
  fullWidth?: boolean
  componentProps?: T
}

export interface ShowDialogProps<T = any> {
  pop: (...args: any[]) => T
}

export function showDialog<T = any, K = any>(
  Content: React.FC<K & ShowDialogProps<T>>,
  options: ShowDialogParams<K> = {}
): Promise<T | undefined> {
  const { componentProps, ...opts } = options

  return new Promise<any>(resolve => {
    ReactDOM.render(
      <DialogWrapper pop={resolve} opts={opts} content={Content} contentProps={componentProps} />,
      getRootElement()
    )
  })
}

const DialogWrapper: React.FC<any> = ({ content: Content, pop, contentProps = {}, opts = {} }) => {
  const { fullWidth } = opts as any
  const onPop = (data?: any) => {
    ReactDOM.unmountComponentAtNode(getRootElement())

    return pop(data)
  }

  return (
    <Modal show onClose={() => onPop()} fullscreen={fullWidth}>
      <Content {...contentProps} pop={onPop} />
    </Modal>
  )
}
