import React from 'react'
import { Spinner } from 'react-bootstrap'
import classnames from 'classnames'

type HeaderItem = string | { name: string; className: string }

interface Props<T> {
  label: string
  header: HeaderItem[]
  data?: T[]
  renderRow: (item: T) => React.ReactNode
  getItemKey: (item: T) => string | number
  onRowClick?: (item: T) => any
  loading?: boolean
  empty?: boolean
}

export const AppTableContainer = <T,>({
  header,
  loading,
  label,
  empty,
  data = [],
  renderRow,
  getItemKey,
  onRowClick,
}: Props<T>) => {
  return (
    <div className="columns">
      <div className="column is-full">
        <div className="card events-card">
          <header className="card-header">
            <p className="card-header-title">{label}</p>
          </header>
          <div className="card-table">
            <div className="content">
              <div className="table-container">
                <table
                  className={classnames('table is-fullwidth is-striped', {
                    'table-hover': !!onRowClick,
                    pointer: !!onRowClick,
                  })}
                >
                  <thead>
                    <tr>
                      {header.map(h => (
                        <th
                          key={typeof h === 'string' ? h : h.name}
                          className={typeof h === 'object' ? h.className : undefined}
                        >
                          {typeof h === 'string' ? h : h.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!empty &&
                      data.map(d => (
                        <tr
                          onClick={onRowClick ? () => onRowClick(d) : undefined}
                          className="table-hover"
                          key={getItemKey(d)}
                        >
                          {renderRow(d)}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {empty && <EmptyState />}
              {loading && (
                <div className="is-full p-md-4 d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EmptyState = () => {
  return (
    <div className="d-flex justify-content-center py-3 fw-bold">
      <span>No data</span>
    </div>
  )
}
