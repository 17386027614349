import React, { createContext, useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getMe } from '../api'
import { AppRoutes } from '../app-routes'
import { axios } from '../axios'
import { ILoginResponse, IUser } from '../data-types'
import { Queries } from '../queries'

interface IUserContext {
  user: IUser
  isLoggedIn: boolean
  handleLogin: (loginResponse: ILoginResponse) => void
  logout: () => void
  isSuperAdmin: boolean
}

function setAxiosAuthHeader(token: string) {
  axios.defaults.headers['Authorization'] = `Bearer ${token}`
}

function removeAxiosAuthHeader() {
  axios.defaults.headers['Authorization'] = undefined
}

// @ts-ignore
const UserContext = createContext<IUserContext>(null)

export const UserContextProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const token = localStorage.getItem('token')

  if (token) setAxiosAuthHeader(token)

  const handleLogin = ({ accessToken, user }: ILoginResponse) => {
    localStorage.setItem('token', accessToken)
    setAxiosAuthHeader(accessToken)

    queryClient.setQueryData(Queries.ME, user)
    history.push(AppRoutes.HOME)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    removeAxiosAuthHeader()
    queryClient.invalidateQueries({ predicate: () => true, refetchActive: false })
    history.push(AppRoutes.LOGIN)
  }

  const { data, isLoading } = useQuery(Queries.ME, getMe, {
    enabled: !!token,
    onError: handleLogout,
  })

  if (isLoading) return null

  const isSuperAdmin = data ? data.superAdmin : false;

  const value: IUserContext = {
    user: data!,
    isLoggedIn: !token ? false : !!data,
    handleLogin,
    logout: handleLogout,
    isSuperAdmin,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
