import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import * as Sentry from "@sentry/react";
import { AppConfig } from './config'

if (AppConfig.SENTRY_DSN && AppConfig.SENTRY_ENV) {
  Sentry.init({
    dsn: AppConfig.SENTRY_DSN,
    environment: AppConfig.SENTRY_ENV
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
