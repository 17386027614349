import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getCoupons } from '../../api'
import { AppRoutes } from '../../app-routes'
import { TablePagination } from '../../components/TablePagination'
import { SearchCouponForm } from '../../forms/search-coupon-form'
import { Queries } from '../../queries'
import { CouponsTable } from './CouponsTable'

const PER_PAGE = 24

export const CouponsScreen = () => {
  const history = useHistory()
  const form = useForm()
  const formValues = form.watch()

  const [page, setPage] = useState(0)
  const { data, isLoading } = useQuery([Queries.COUPONS, page, formValues], () =>
    getCoupons(formValues, page, PER_PAGE)
  )


  return (
    <>
      <Row className="justify-content-between p-2">
        <Col className="ml-1">
          <SearchCouponForm form={form} />
        </Col>
        <Col className="mr-1 d-flex justify-content-end">
          <div>
            <Button onClick={() => history.push(AppRoutes.COUPONS_CREATE)} className="is-info primary-bg">
              New coupon
            </Button>
          </div>
        </Col>
      </Row>
      <CouponsTable coupons={data?.coupons ?? []} loading={isLoading} />
      {!isLoading && data && (
        <Row xs={5} className="justify-content-end pb-6">
          <TablePagination page={page} onChange={page => setPage(page)} pageCount={data.totalCount / PER_PAGE || 1} />
        </Row>
      )}
    </>
  )
}
