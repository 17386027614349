import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getUsers } from '../../api'
import { AppRoutes } from '../../app-routes'
import { TablePagination } from '../../components/TablePagination'
import { Queries } from '../../queries'
import { UsersTable } from './UsersTable';
import { useDebounceFn } from '../../hooks/use-debounce';
import { SearchInput } from '../../components/SearchInput';
import { useUserContext } from '../../hooks/user-context';

const PER_PAGE = 24

export const UsersScreen = () => {
  const history = useHistory()
  const userContext = useUserContext();

  const [term, setTerm] = useState('')
  const [page, setPage] = useState(0)

  const { data, isLoading } = useQuery([Queries.USERS, term, page, history], () => getUsers(term, page, PER_PAGE))

  const onSearch = useDebounceFn((term: string) => {
    setPage(0)
    setTerm(term)
  })

  return (
    <>
      <Row className="justify-content-between p-2">
        <Col lg={5} md={12} className="ml-1">
          <SearchInput addonLabel="Search" placeHolder="Search users" onChange={onSearch} />
        </Col>
        {userContext?.isSuperAdmin && (
            <Col lg={2} md={12} className="mr-4">
                <Row className="justify-content-end flex-shrink-1 flex-grow-0">
                    <Button onClick={() => history.push(AppRoutes.USERS_CREATE)} className="is-info primary-bg">
                        New user
                    </Button>
                </Row>
            </Col>
        )}
      </Row>
      <UsersTable users={data?.users ?? []} loading={isLoading} />
      {!isLoading && data && (
        <Row xs={5} className="justify-content-end">
          <TablePagination pageCount={data?.totalCount / PER_PAGE} page={page} onChange={setPage} />
        </Row>
      )}
    </>
  )
}
