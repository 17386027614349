export enum FreeShipmentImportStatus {
  READY = 0,
  IN_PROGRESS = 1,
  DONE = 2,
}

export enum InfluencerType {
  NANO = 'Nano',
  PAID = 'Paid',
}

export enum FreeShipmentImportEntryStatus {
  READY = 0,
  ERROR = 1,
  SUCCESS = 2,
  SKIPPED = 3,
}

export enum SubscriptionStatus {
    future = 'future',
    in_trial = 'in_trial',
    active = 'active',
    non_renewing = 'non_renewing',
    paused = 'paused',
    cancelled = 'cancelled',
}