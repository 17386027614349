export const AppRoutes = {
  LOGIN: '/login',
  HOME: '/',
  IMPORTS: '/imports',
  IMPORT: {
    url: '/imports/:id',
    getRoute: (id: EntityId) => `/imports/${id}`,
  },
  IMPORTS_CREATE: '/imports/create',
  COUPONS: '/coupons',
  COUPON: {
    url: '/coupons/:id',
    getRoute: (id: EntityId) => `/coupons/${id}`,
  },
  COUPONS_CREATE: '/coupons/create',
  COUPONS_UPDATE: {
    url: '/coupons/edit/:id',
    getRoute: (id: EntityId) => `/coupons/edit/${id}`,
  },
  GIFT_CARDS: '/gift-cards',
  EMAILS: '/emails',
  USERS: '/users',
  USER: {
    url: '/users/:id',
    getRoute: (id: EntityId) => `/users/${id}`,
  },
  USERS_CREATE: '/users/create',
  OUT_OF_STOCK: '/out-of-stock',
  OUT_OF_STOCK_START: '/out-of-stock/start'
} as const
