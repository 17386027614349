import React from 'react'
import {AppTableContainer} from '../../components/AppTableContainer'
import {IGiftCard} from '../../data-types'

interface Props {
  giftCards: IGiftCard[]
  loading?: boolean
}

export const GiftCardsTable: React.FC<Props> = ({loading, giftCards}) => {
  return (
    <AppTableContainer
      label="Gift Cards"
      header={['ID', 'Subscription ID', 'Order ID', 'Addon Variant ID', 'Coupon Name', 'Redeemed', 'Created At']}
      loading={loading}
      data={giftCards}
      renderRow={renderRow}
      getItemKey={c => c.id}
      empty={!loading && !giftCards.length}
    />
  )
}

const renderRow = (giftCard: IGiftCard) => {
  return (
    <>
      <td>{giftCard.id}</td>
      <td>{giftCard.subscriptionId}</td>
      <td>{giftCard.orderId}</td>
      <td>{giftCard.giftCardAddonVariantId}</td>
      <td>{giftCard.coupon.name}</td>
      <td>{giftCard.redeemed ? 'Yes' : 'No'}</td>
      <td>{giftCard.createdAt}</td>
    </>
  )
}
