import React from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'

interface IAppButtonProps {
  isLoading?: boolean
}

export type AppButtonProps = IAppButtonProps & ButtonProps

export const AppButton: React.FC<AppButtonProps> = ({ children, isLoading, ...props }) => {
  return <Button {...props}>{!isLoading ? children : <Spinner size="sm" animation="border" />}</Button>
}
