import { Container } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { AppNavbar } from '../components/Navbar'
import { useUserContext } from '../hooks/user-context'
import { CouponScreen } from '../screens/Coupons/CouponScreen'
import { CouponsScreen } from '../screens/Coupons/CouponsScreen'
import { CreateCouponScreen } from '../screens/Coupons/CreateCouponScreen'
import { EmailsScreen } from '../screens/EmailsScreen'
import { HomeScreen } from '../screens/HomeScreen'
import { CreateImportScreen } from '../screens/Imports/CreateImportScreen'
import { ImportScreen } from '../screens/Imports/ImportScreen'
import { ImportsScreen } from '../screens/Imports/ImportsScreen'
import { UsersScreen } from '../screens/Users/UsersScreen';
import { UserScreen } from '../screens/Users/UserScreen';
import { CreateUserScreen } from '../screens/Users/CreateUserScreen';
import {UpdateCouponScreen} from "../screens/Coupons/UpdateCouponScreen";
import {OutOfStockScreen} from "../screens/OutOfStock/OutOfStockScreen";
import {OutOfStockStartScreen} from "../screens/OutOfStock/OutOfStockStartScreen";
import { GiftCardsScreen } from '../screens/GiftCards/GiftCardsScreen'

export const MainLayout = () => {
  const userContext = useUserContext()

  if (!userContext.isLoggedIn) return <Redirect to={AppRoutes.LOGIN} />

  return (
    <>
      <AppNavbar />
      <Container>
        <Switch>
          <Route exact path={AppRoutes.HOME} component={HomeScreen} />
          <Route exact path={AppRoutes.OUT_OF_STOCK_START} component={OutOfStockStartScreen} />
          <Route exact path={AppRoutes.OUT_OF_STOCK} component={OutOfStockScreen} />
          <Route exact path={AppRoutes.IMPORTS_CREATE} component={CreateImportScreen} />
          <Route exact path={AppRoutes.IMPORTS} component={ImportsScreen} />
          <Route exact path={AppRoutes.IMPORT.url} component={ImportScreen} />
          <Route exact path={AppRoutes.COUPONS_CREATE} component={CreateCouponScreen} />
          <Route exact path={AppRoutes.COUPONS} component={CouponsScreen} />
          <Route exact path={AppRoutes.COUPON.url} component={CouponScreen} />
          <Route exact path={AppRoutes.COUPONS_UPDATE.url} component={UpdateCouponScreen} />
          <Route exact path={AppRoutes.GIFT_CARDS} component={GiftCardsScreen} />
          <Route exact path={AppRoutes.EMAILS} component={EmailsScreen} />
          {userContext?.isSuperAdmin && (
                <>
                    <Route exact path={AppRoutes.USERS_CREATE} component={CreateUserScreen} />
                    <Route exact path={AppRoutes.USERS} component={UsersScreen} />
                    <Route exact path={AppRoutes.USER.url} component={UserScreen} />
                </>
            )}
        </Switch>
      </Container>
    </>
  )
}
