import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { AppForm, FormSelectField } from '../components/Form'
import { SearchInput } from '../components/SearchInput'

const DiscountOptions = [
  { label: 'Fixed amount', value: 'fixed_amount' },
  { label: 'Percentage', value: 'percentage' },
  { label: 'Free product', value: 'free_product' },
]
const DurationOptions = [
  { label: 'One time', value: 'one_time' },
  { label: 'Forever', value: 'forever' },
  { label: 'Limited period', value: 'limited_period' },
]

export const SearchCouponForm: React.FC<{ form: UseFormReturn }> = ({ form }) => {
  return (
    <AppForm form={form} onSubmit={() => {}}>
      <Controller
        name="term"
        render={({ field }) => {
          return (
            <SearchInput
              value={field.value ?? ''}
              addonLabel="Search"
              placeHolder="Search by name, full name or email"
              onChange={field.onChange}
            />
          )
        }}
      />
      <div className="d-flex">
        <div>
          <FormSelectField name="discountType" placeholder="Discount type" options={DiscountOptions} />
        </div>
        <div className="pl-2">
          <FormSelectField name="durationType" placeholder="Duration type" options={DurationOptions} />
        </div>
      </div>
    </AppForm>
  )
}
