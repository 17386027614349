import { UseFormProps, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z, ZodSchema } from 'zod'

export function useZodForm<T extends ZodSchema<any, any>>(
  schema: T,
  formOpts?: UseFormProps
): UseFormReturn<z.infer<typeof schema>> {
  const form = useForm({
    resolver: zodResolver(schema),
    ...formOpts,
  })

  return form
}
