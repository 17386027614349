import React from 'react'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import ReactPaginate from 'react-paginate'

interface Props {
  pageCount: number
  initialPage?: number
  page?: number
  onChange?: (page: number) => any
}

export const TablePagination: React.FC<Props> = ({ pageCount, initialPage, page, onChange }) => {
  return (
    <ReactPaginate
      previousLabel={<MdArrowBack />}
      nextLabel={<MdArrowForward />}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      initialPage={initialPage}
      forcePage={page}
      onPageChange={a => onChange && onChange(a.selected)}
      containerClassName="pagination"
      activeClassName="pagination-active-page"
    />
  )
}
