import React from 'react'
import { Row, Spinner } from 'react-bootstrap'
import {
  useMutation,
  useQuery,
} from 'react-query'
import {
    QueryObserverBaseResult,
} from "react-query/types/core/types";
import {
  RouteChildrenProps,
  useHistory,
  useParams
} from 'react-router-dom'
import { IUser } from '../../data-types'
import { Queries } from '../../queries'
import {
  deleteUser,
  demoteUser,
  getUser,
  promoteUser,
} from '../../api';
import { AppButton } from '../../components/AppButton';
import { showToast } from '../../message';
import { AppRoutes } from '../../app-routes';
import { useUserContext } from '../../hooks/user-context';

export const UserScreen: React.FC<RouteChildrenProps> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, refetch } = useQuery([Queries.USER(id)], () => getUser(id))

  return (
    <>
      <div className="columns">
        <div className="column is-full">
          <div className="card events-card">
            {!isLoading && data && <Content data={data} refetch={refetch} />}
            {isLoading && (
              <Row className="justify-content-center py-6">
                <Spinner animation="border" />
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const Content: React.FC<{ data: IUser, refetch: QueryObserverBaseResult['refetch']}> = ({ data, refetch }) => {
  const history = useHistory()
  const userContext = useUserContext();

  const { id, email, superAdmin } = data;

  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess: () => {
      showToast('Deleted', 'Successfully deleted a user', 'info');
      history.push(AppRoutes.USERS);
    }
  })

  const promoteUserMutation = useMutation(promoteUser, {
    onSuccess: () => {
      showToast('Promoted', 'Successfully promoted a user', 'success');
      if (refetch && typeof refetch === 'function') refetch();
    }
  })

  const demoteUserMutation = useMutation(demoteUser, {
    onSuccess: () => {
        showToast('Demoted', 'Successfully demoted a user', 'warning')
        if (refetch && typeof refetch === 'function') refetch();
    }
  })

  return (
      <div className="card-content">
        {userContext?.isSuperAdmin && (
            <div className="mr-1 d-flex justify-content-end">
                <AppButton isLoading={deleteUserMutation.isLoading} onClick={() => deleteUserMutation.mutate(id)} variant="danger">
                    Delete user
                </AppButton>
            </div>
        )}
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <tbody>
              <tr>
                <td>ID:</td>
                <td>{id}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>Super admin:</td>
                <td>{superAdmin.toString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {userContext?.isSuperAdmin && (
            <div className="pr-5">
              <span>
                <AppButton isLoading={promoteUserMutation.isLoading} onClick={() => promoteUserMutation.mutate(id)} variant="outline-success">
                  Promote user
                </AppButton>
              </span>
                &nbsp;
                <span>
                    <AppButton isLoading={demoteUserMutation.isLoading} onClick={() => demoteUserMutation.mutate(id)} variant="outline-warning">
                      Demote user
                    </AppButton>
              </span>
            </div>
        )}
      </div>
  )
}
