import { Container, Card, Row } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { Redirect } from 'react-router-dom'
import { login } from '../api'
import { AppRoutes } from '../app-routes'
import { LoginForm } from '../forms/login-form'
import { LoginFormSchema } from '../forms/schemas'
import { useZodForm } from '../hooks/use-zod'
import { useUserContext } from '../hooks/user-context'
import { showToast } from '../message'

export const LoginScreen = () => {
  const userContext = useUserContext()
  const form = useZodForm(LoginFormSchema)

  const { mutateAsync } = useMutation(login, {
    onSuccess: userContext.handleLogin,
    onError: () => {
      showToast('Login error', 'Invalid email or password', 'danger')
      form.reset({ email: form.getValues('email'), password: '' })
    },
  })

  if (userContext.isLoggedIn) return <Redirect to={AppRoutes.HOME} />

  return (
    <Container className="h-100 ">
      <Row className="flex-column justify-content-center align-items-center h-75" xs={4}>
        <div className="pb-4 d-flex justify-content-center">
          <span className="fw-bold">Estrid admin login</span>
        </div>
        <Card className="p-4">
          <LoginForm onSubmit={mutateAsync} form={form} />
        </Card>
      </Row>
    </Container>
  )
}
