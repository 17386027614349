import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { AppButton } from '../components/AppButton'
import { AppForm, AppFormProps, FormButton, FormFileInput, FormSelectField, FormTextArea } from '../components/Form'
import { useZodForm } from '../hooks/use-zod'
import { importFormSchema } from './schemas'

export const ImportForm: React.FC<AppFormProps<any>> = ({ onSubmit }) => {
  const history = useHistory()
  const form = useZodForm(importFormSchema)

  return (
    <div className="card-content">
      <div className="content">
        <AppForm form={form} onSubmit={onSubmit}>
          <div className="field">
            <FormSelectField
              placeholder="Select import type"
              label="Import type"
              name="importType"
              options={['Nano', 'Paid']}
            />
          </div>

          <div className="field">
            <FormFileInput name="file" label="Import file" accept=".csv" />
          </div>

          <div className="field">
            <FormTextArea label="Note" name="note" />
          </div>

          <div className="field is-grouped">
            <div className="control">
              <FormButton>Submit</FormButton>
            </div>
            <div className="control">
              <AppButton onClick={() => history.push(AppRoutes.IMPORTS)} variant="light">
                Cancel
              </AppButton>
            </div>
          </div>
        </AppForm>
      </div>
    </div>
  )
}
