import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { AppForm, AppFormProps, FormButton, FormInputField } from '../components/Form'

export const LoginForm: React.FC<AppFormProps & { form: UseFormReturn<any> }> = ({ form, onSubmit }) => {
  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <FormInputField label="Email" name="email" />
      <div className="pt-5">
        <FormInputField label="Password" type="password" name="password" />
      </div>
      <div className="pt-5 d-flex justify-content-center ">
        <FormButton className="w-100">Login</FormButton>
      </div>
    </AppForm>
  )
}
