import { Spinner } from 'react-bootstrap'

export const DataLoadingGuard: React.FC<{ isLoading: boolean }> = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    )

  return <>{children}</>
}
