import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { AppButton } from '../components/AppButton'
import { DataLoadingGuard } from '../components/DataLoadingGuard'
import {
  AppForm,
  AppFormProps,
  FormButton,
  FormSelectField,
  FormInputField,
  FormCheckbox
} from '../components/Form'
import { Alert } from 'react-bootstrap'
import { useZodForm } from '../hooks/use-zod'
import { outOfStockStartFormSchema } from './schemas'
import { useQuery } from 'react-query'
import { Queries } from '../queries'
import { getOutOfStockStartData } from '../api'

export const OutOfStockForm: React.FC<AppFormProps<any>> = ({ onSubmit }) => {
  const history = useHistory()
  const form = useZodForm(outOfStockStartFormSchema)

  const marketOptions = [
    { value: 'sweden', label: 'Sweden'  },
    { value: 'united-kingdom', label: 'UK' }
  ]

  const { data, isLoading } = useQuery([Queries.OUT_OF_STOCK_START_DATA, history], () => getOutOfStockStartData())

  const productOptions = data?.products ?? [];

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <div className="box">
        <Alert variant='warning'>
          Before starting product removal task <b>first mark it as out of stock in StoryBlok</b> (for all variants on specified market).
          This will ensure that product won't be available for purchase during and after this task execution.
        </Alert>

        <Alert variant='secondary'>
          Out of stock task looks for active or paused subscriptions with specified product.
          If subscription contains other products that are still in stock then it just removed out-of-stock product from subscription.
          If subscription contains only one product that is out-of-stock then it cancels this subscription.
        </Alert>

        <div className="mt-4">
          <FormSelectField
            label="Market*"
            name="marketId"
            options={marketOptions}
            placeholder="Select market"
          />
        </div>

        
        <div className="mt-4">
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Product*"
              name="productSku"
              options={productOptions}
              placeholder="Select product"
            />
          </DataLoadingGuard>
        </div>


        <div className="mt-4">
          <FormInputField
            label="Customer IDs (for testing)"
            name="customerIds"
            placeholder="Chargebee customer IDs separated by comma"
          />
        </div>

        <div className="mt-4">
          <FormCheckbox label="Dry run (for testing)" name='dryRun' defaultValue={false} />
        </div>

      </div>

      <div className="d-flex">
        <FormButton>Start</FormButton>
        <div className="ml-2">
          <AppButton variant="light" onClick={() => history.push(AppRoutes.OUT_OF_STOCK)}>
            Cancel
          </AppButton>
        </div>
      </div>

    </AppForm>
  )
}
