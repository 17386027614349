import { QueryClient, QueryClientProvider } from 'react-query'
import { MainRouter } from './main-router'
import { ModalContainer } from './modal-container'
import { QueryConfig } from './query-config'

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: QueryConfig,
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ModalContainer>
        <MainRouter />
      </ModalContainer>
    </QueryClientProvider>
  )
}
