import React from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { deleteCoupon, getCoupon } from '../../api'
import { ICoupon } from '../../data-types'
import { Queries } from '../../queries'
import { AppRoutes } from "../../app-routes";
import { showToast } from "../../message";

export const CouponScreen = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(Queries.COUPON(id), () => getCoupon(id))
  const history = useHistory()
  const queryClient = useQueryClient()


  const { mutateAsync } = useMutation(deleteCoupon, {
    onSuccess: () => {
      showToast('Deleted', 'Successfully deleted coupon')
      queryClient.invalidateQueries([Queries.COUPONS])
      history.push(AppRoutes.COUPONS)
    },
    onError: () => {
      showToast('error', 'Error occurred while deleting coupon', 'danger')
    },
  })

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Coupon Info</p>
              <Col lg={2} md={12} className="mr-4">
                <Button onClick={() => history.push(AppRoutes.COUPONS_UPDATE.getRoute(id))}
                  className="is-info primary-bg">
                  Edit coupon
                </Button>
              </Col>
              <Col lg={2} md={12} className="mr-4">
                <Button onClick={() => mutateAsync(id)}
                  className="is-info primary-bg">
                  Delete coupon
                </Button>
              </Col>
            </header>
            {!isLoading && data && <Content coupon={data} />}
            {isLoading && (
              <Row className="justify-content-center py-6">
                <Spinner animation="border" />
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const Content: React.FC<{ coupon: ICoupon }> = ({ coupon }) => {
  return (
    <div className="card-content">
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <tbody>
            <tr>
              <td>ID:</td>
              <td>{coupon.id}</td>
            </tr>
            <tr>
              <td>Coupon code:</td>
              <td>{coupon.name}</td>
            </tr>
            <tr>
              <td>Influencers Name:</td>
              <td>{coupon.fullName}</td>
            </tr>
            <tr>
              <td>Team:</td>
              <td>{coupon.team}</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>{coupon.invoiceName}</td>
            </tr>
            <tr>
              <td>Discount type:</td>
              {<td>{coupon.discountType.label}</td>}
            </tr>
            {coupon.discountType.value === 'fixed_amount' &&
              <tr>
                <td>Amount:</td>
                <td>{(coupon.discountAmounts || []).map(discount => {
                  return `${discount.marketId}:${discount.amount}`
                }).join(', ')}</td>
              </tr>}
            {coupon.discountType.value === 'percentage' &&
              <tr>
                <td>Percentage amount:</td>
                <td>{coupon.discountPercentage}</td>
              </tr>}
            {coupon.discountType.value === 'free_product' &&
              <tr>
                <td>Free product:</td>
                <td>{coupon.freeProductAddonGroup}</td>
              </tr>}
            {coupon.discountType.value === 'free_product' && coupon.freeAddonVariant &&
              <tr>
                <td>Free product variant:</td>
                <td>{coupon.freeAddonVariant}</td>
              </tr>}
            <tr>
              <td>Duration type:</td>
              <td>{coupon.durationType.label}</td>
            </tr>
            {coupon.durationType.value === 'limited_period' &&
              <tr>
                <td>Period:</td>
                <td>{coupon.period} {coupon.periodUnit}</td>
              </tr>}
            <tr>
              <td>Redeem count:</td>
              <td>{coupon.redeemCount}</td>
            </tr>
            <tr>
              <td>Markets:</td>
              <td>{coupon.markets?.join(', ')}</td>
            </tr>
            <tr>
              <td>Valid from:</td>
              <td>{coupon.validFrom}</td>
            </tr>
            <tr>
              <td>Valid until:</td>
              <td>{coupon.validUntil}</td>
            </tr>
            <tr>
              <td>Apply on:</td>
              <td>{(coupon.applyOn.value === 'each_specified_item' && coupon.applyOnAddons) ? coupon.applyOnAddons.join(', ') : coupon.applyOn.label}</td>
            </tr>
            {coupon.applyOnAddonVariants && (
              <tr>
                <td>Apply on variants:</td>
                <td>{coupon.applyOnAddonVariants.join(', ')}</td>
              </tr>
            )}
            <tr>
              <td>Required product in cart:</td>
              <td>{coupon.requiredAddon}</td>
            </tr>
            <tr>
              <td>Required product variant in cart:</td>
              <td>{coupon.requiredAddonVariant}</td>
            </tr>
            <tr>
              <td>Valid for subscription purchases only (If checked, the coupon will not work for one-offs or standalones):</td>
              <td>{coupon.subscriptionRequired ? 'T' : 'F'}</td>
            </tr>
            <tr>
              <td>Can be used by:</td>
              <td>{coupon.canBeUsedBy?.label}</td>
            </tr>
            <tr>
              <td>Total redemptions:</td>
              <td>{coupon.maxRedemptions}</td>
            </tr>
            <tr>
              <td>Total redemptions per user:</td>
              <td>{coupon.maxRedemptionsPerUser}</td>
            </tr>
            <tr>
              <td>Minimum amount required:</td>
              <td>{coupon.minimumAmount ? coupon.minimumAmount.map(discount => {
                return `${discount.marketId}:${discount.amount}`
              }).join(', ') : []}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
