import { Container, Nav, Navbar } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { useUserContext } from '../hooks/user-context'

export const AppNavbar = () => {
  const history = useHistory()
  const userContext = useUserContext()

  const host = window.location.host;
  const isApril = !!host.match(/(april|localhost)/i);

  return (
    <Navbar expand="md" bg="light" className="main-navbar">
      <Container>
        <Navbar.Brand onClick={() => history.push(AppRoutes.HOME)} className="navbar-item brand-text pointer">
          Estrid Admin
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link onClick={() => history.push(AppRoutes.IMPORTS)} className="navbar-item">
              Imports
            </Nav.Link>
            <Nav.Link onClick={() => history.push(AppRoutes.EMAILS)} className="navbar-item">
              Emails
            </Nav.Link>
            <Nav.Link onClick={() => history.push(AppRoutes.COUPONS)} className="navbar-item">
              Coupons
            </Nav.Link>
            <Nav.Link onClick={() => history.push(AppRoutes.GIFT_CARDS)} className="navbar-item">
              Gift Cards
            </Nav.Link>
            { userContext?.isSuperAdmin && <Nav.Link onClick={() => history.push(AppRoutes.USERS)} className="navbar-item">
              Users
            </Nav.Link> }
            { isApril &&
              <Nav.Link onClick={() => history.push(AppRoutes.OUT_OF_STOCK)} className="navbar-item">
                Out of Stock
              </Nav.Link>
            }
          </Nav>
          <Nav>
            <Nav.Link onClick={userContext.logout} className="navbar-item">
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Navbar.Toggle className="navbar-burger burger" aria-controls="responsive-navbar-nav" />
    </Navbar>
  )
}
