import React, {ReactNode} from 'react'
import {Form} from 'react-bootstrap'
import {FormCheckType} from 'react-bootstrap/esm/FormCheck'
import {FieldValues, FormProvider, FormState, UnpackNestedValue, useFormContext, UseFormReturn} from 'react-hook-form'
import {AppButton, AppButtonProps} from './AppButton'

export interface AppFormProps<T = any> {
  onSubmit: (form: T) => any
  initialValues?: T
}

interface Props<T extends FieldValues> {
  children: any
  form: UseFormReturn<T>
  onSubmit: (form: UnpackNestedValue<T>) => any
  testId?: string
}

export const AppForm = <T extends FieldValues, >({form, onSubmit, children, testId}: Props<T>) => {
  return (
    <FormProvider {...form}>
      <form 
        onSubmit={form.handleSubmit(onSubmit)}
        data-testid={testId}
      >{children}</form>
    </FormProvider>
  )
}

const ErrorText: React.FC<{ name: string; formState: FormState<any> }> = ({name, formState}) => {
  const error = formState.errors[name]

  if (!error) return null

  return (
    <div>
      <Form.Text className="text-danger">{error.message}</Form.Text>
    </div>
  )
}

interface FormFieldBase {
  name: string
  defaultValue?: any
  testId?: string
}

interface FormInputFieldProps extends FormFieldBase {
  label?: string
  placeholder?: string
  text?: string | ReactNode
  type?: string
  as?: React.ElementType
  rows?: number
  step?: number
  min?: number
  disabled?: boolean
  valueAsNumber?: boolean
  setValueAs?: (value: any) => any;
}

export const FormInputField: React.FC<FormInputFieldProps> = ({
                                                                name,
                                                                label,
                                                                text,
                                                                placeholder,
                                                                type,
                                                                as,
                                                                rows,
                                                                defaultValue,
                                                                step,
                                                                min,
                                                                disabled = false,
                                                                testId,
                                                                valueAsNumber = false,
                                                                setValueAs,
                                                              }) => {
  const {register, formState} = useFormContext()

  return (

    <Form.Group>
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Control {...register(name, { valueAsNumber, disabled, setValueAs })} type={type} step={step} placeholder={placeholder} as={as} rows={rows}
                    defaultValue={defaultValue} min={min} data-testid={testId}
                    onWheel={() => (document.activeElement as HTMLElement).blur()}/>
      <ErrorText name={name} formState={formState}/>
      {text && <Form.Text className="text-muted">{text}</Form.Text>}
    </Form.Group>
  )
}

interface FormBooleanFieldProps extends FormFieldBase {
  label: string
}

export const FormBooleanField: React.FC<FormBooleanFieldProps> = ({name, label, defaultValue}) => {
  const {register, formState} = useFormContext()

  return (
    <>
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Check defaultChecked={defaultValue} {...register(name)}   />
      <ErrorText name={name} formState={formState}/>
    </>
  )
}

interface CheckboxProps extends FormFieldBase {
  label?: string
  type?: FormCheckType
  value?: string
  onChange?: (e: any) => void;
  preventDeafultChange?: boolean
  disabled?: boolean
}

export const FormCheckbox: React.FC<CheckboxProps> = ({name, label, type = 'checkbox', defaultValue, value, testId, onChange, preventDeafultChange, disabled = false}) => {
  const {register, formState} = useFormContext()
  const field = { ...register(name) }

  const fieldOnChange = (e: any) => {
    if (!preventDeafultChange) {
      field.onChange(e)
    }

    if (onChange) {
      onChange(e)
    }
  }

  return (
    <>
      <Form.Check 
        onChange={fieldOnChange}
        onBlur={field.onBlur}
        ref={field.ref}
        name={field.name}
        defaultChecked={defaultValue} 
        type={type} 
        label={label} 
        value={value} 
        data-testid={testId}
        disabled={disabled}
      />
      <ErrorText name={name} formState={formState}/>
    </>
  )
}

interface FormSelectFieldProps extends FormFieldBase {
  options: { value: any; label: string }[] | string[]
  placeholder?: string
  text?: string
  label?: string
  defaultValue?: string;
  disabled?: boolean
  onChange?: (e: any) => void;
  setValueAs?: (value: any) => any
}

export const FormSelectField: React.FC<FormSelectFieldProps> = ({ name, options, placeholder, text, label, defaultValue, disabled, testId, onChange, setValueAs }) => {
  const { register, formState } = useFormContext()
  const field = { ...register(name, {disabled, setValueAs}) }
  const fieldOnChange = onChange || field.onChange
  return (
    <Form.Group>
      {label && <Form.Label className="fw-bold">{label}</Form.Label>}
      <Form.Select
        onChange={fieldOnChange}
        onBlur={field.onBlur}
        ref={field.ref}
        name={field.name}
        disabled={disabled}
        defaultValue={defaultValue}
        data-testid={testId}
      >
        {placeholder && <option value={''}>{placeholder}</option>}
        {options.map(o => {
          const value = typeof o === 'object' ? o.value : o
          const label = typeof o === 'object' ? o.label : o

          return (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })}
      </Form.Select>
      <ErrorText name={name} formState={formState} />
      <Form.Text>{text}</Form.Text>
    </Form.Group>
  )
}

interface FormDateTimeProps extends FormInputFieldProps {
}

export const FormDateTimeField: React.FC<FormDateTimeProps> = props => {
  return <FormInputField {...props} type="datetime-local"/>
}

export const FormButton: React.FC<AppButtonProps> = props => {
  const {formState} = useFormContext()

  return <AppButton {...props} isLoading={formState.isSubmitting} type="submit"/>
}

export const FormTextArea: React.FC<Exclude<FormInputFieldProps, 'type'>> = props => {
  return <FormInputField {...props} as="textarea" rows={4}/>
}

interface FormFileInputProps extends FormInputFieldProps {
  accept?: string
}

export const FormFileInput: React.FC<FormFileInputProps> = ({name, label, accept}) => {
  const form = useFormContext()

  return (
    <>
      {label && <Form.Label className="fw-bold">{label}</Form.Label>}
      <div className="control">
        <input {...form.register('file')} type="file" accept={accept}/>
      </div>
      <ErrorText formState={form.formState} name={name}/>
    </>
  )
}
