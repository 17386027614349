import { AxiosError } from 'axios'
import { IAxiosErrData } from './data-types'
import { showToast } from './message'

export function throttle<T extends (...params: any) => void>(fn: T, limit = 250): T {
  let timer: NodeJS.Timeout | undefined

  // @ts-ignore
  return (...params: any) => {
    if (timer) return

    timer = setTimeout(() => {
      if (timer) {
        clearTimeout(timer)
        timer = undefined
      }
    }, limit)

    fn(...params)
  }
}

export function debouncePromise<T extends (...params: any) => Promise<any>>(fn: T, limit = 250): T {
  let timer: NodeJS.Timeout | undefined

  // @ts-ignore
  return (...params: any) => {
    return new Promise(res => {
      if (timer) {
        clearTimeout(timer)
        timer = undefined
      }

      timer = setTimeout(() => {
        fn(...params).then(res)
      }, limit)
    })
  }
}

export function getDataFromAxiosErr(err: unknown): IAxiosErrData {
  return (err as AxiosError)?.response?.data
}

export function defaultQueryErrorHandler(err: unknown) {
  const axiosErrMsg = getDataFromAxiosErr(err)?.message

  showToast('Error', typeof axiosErrMsg === 'string' ? axiosErrMsg : JSON.stringify(axiosErrMsg), 'danger')
}
