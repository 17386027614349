import { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { getGiftCards } from '../../api'
import { TablePagination } from '../../components/TablePagination'
import { Queries } from '../../queries'
import { GiftCardsTable } from './GiftCardsTable'
import { SearchGiftCardForm } from '../../forms/search-gift-card-form'

const PER_PAGE = 24

export const GiftCardsScreen = () => {
  const form = useForm()
  const formValues = form.watch()

  const [page, setPage] = useState(0)
  const { data, isLoading } = useQuery([Queries.GIFT_CARDS, page, formValues], () =>
    getGiftCards(formValues, page, PER_PAGE)
  )

  return (
    <>
      <Row className="justify-content-between p-2">
        <Col className="ml-1">
          <SearchGiftCardForm form={form} />
        </Col>
        <Col className="mr-1 d-flex justify-content-end"></Col>
      </Row>
      <GiftCardsTable giftCards={data?.giftCards ?? []} loading={isLoading} />
      {!isLoading && data && (
        <Row xs={5} className="justify-content-end pb-6">
          <TablePagination page={page} onChange={page => setPage(page)} pageCount={data.totalCount / PER_PAGE || 1} />
        </Row>
      )}
    </>
  )
}
