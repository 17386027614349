import { Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { useUserContext } from '../hooks/user-context'

export const HomeScreen = () => {
  const history = useHistory()
  const userContext = useUserContext()

  return (
    <>
      <section className="hero is-info welcome">
        <div className="hero-body">
          <Container>
            <h1 className="title">Hello, {userContext.user.email}.</h1>
            <h2 className="subtitle">I hope you are having a great day!</h2>
          </Container>
        </div>
      </section>
      <section className="info-tiles">
        <div className="tile is-ancestor has-text-centered">
          <div className="tile is-parent">
            <article className="tile is-child box">
              <p className="title">Imports</p>
              <Button onClick={() => history.push(AppRoutes.IMPORTS)} variant="link" className="subtitle">
                View
              </Button>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <p className="title">New Import</p>
              <Button onClick={() => history.push(AppRoutes.IMPORTS_CREATE)} variant="link" className="subtitle">
                Create
              </Button>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}
