import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AppRoutes } from '../../app-routes'
import { AppTableContainer } from '../../components/AppTableContainer'
import { IFreeShipmentImport } from '../../data-types'
import { FreeShipmentImportStatus } from '../../enums'

interface Props {
  imports?: IFreeShipmentImport[]
  loading?: boolean
}

export const ImportsTable: React.FC<Props> = ({ imports, loading }) => {
  const history = useHistory()

  return (
    <AppTableContainer
      label="Imports"
      header={['ID', 'Import type', 'Note', 'File Name', 'Created At', 'Has errors', 'Import status']}
      renderRow={renderRow}
      data={imports}
      getItemKey={i => i.id}
      onRowClick={i => history.push(AppRoutes.IMPORT.getRoute(i.id))}
      loading={loading}
      empty={!loading && !imports?.length}
    />
  )
}

const renderRow = (shipmentImport: IFreeShipmentImport) => {
  const { id, importType, note, originalFileName, createdAt, status, hasErrors } = shipmentImport
  return (
    <>
      <td>
        <Link to="">{id}</Link>
      </td>
      <td>{importType}</td>
      <td>{note}</td>
      <td>{originalFileName}</td>
      <td>{createdAt}</td>
      <td>
        <StatusBadge isError={hasErrors > 0} />
      </td>
      <td>
        <ImportStatusBadge status={status} />
      </td>
    </>
  )
}

const StatusBadge: React.FC<{ isError: boolean }> = ({ isError }) => {
  if (isError) return <span className="tag is-danger">Yes</span>

  return <span className="tag is-success">No</span>
}

const ImportStatusBadge: React.FC<{ status: FreeShipmentImportStatus }> = ({ status }) => {
  switch (status) {
    case FreeShipmentImportStatus.READY:
      return <span className="tag is-info is-light">Ready</span>
    case FreeShipmentImportStatus.IN_PROGRESS:
      return <span className="tag is-warning">In progress</span>
    case FreeShipmentImportStatus.DONE:
      return <span className="tag is-success">Done</span>
    default:
      return <span className="tag is-link">Skipped</span>
  }
}
