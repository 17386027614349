import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { AppForm } from '../components/Form'
import { SearchInput } from '../components/SearchInput'

export const SearchGiftCardForm: React.FC<{ form: UseFormReturn }> = ({ form }) => {
  return (
    <AppForm form={form} onSubmit={() => {}}>
      <Controller
        name="term"
        render={({ field }) => {
          return (
            <SearchInput
              value={field.value ?? ''}
              addonLabel="Search"
              placeHolder="Search gift cards"
              onChange={field.onChange}
            />
          )
        }}
      />
    </AppForm>
  )
}
