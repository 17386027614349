import { Row, Col, Button, Card, Alert, Table } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getOutOfStockJobStatus } from '../../api'
import { AppRoutes } from '../../app-routes'
import { Queries } from '../../queries'

export const OutOfStockScreen = () => {
  const history = useHistory()
  const { data } = useQuery([Queries.OUT_OF_STOCK_JOB_STATUS, history], () => getOutOfStockJobStatus())

  const isFailed = data?.job?.status === 'failed';
  const isCompleted = data?.job?.status === 'completed';
  const isActive = !!data?.job?.status && !isFailed && !isCompleted;
  const noJob = !data?.job;

  return (
    <>
      <Row>
        <Col xs={7}></Col>
        <Col xs={2} className="pb-2">
        <div className="d-grid gap-2">
        <Button onClick={() => history.push(AppRoutes.OUT_OF_STOCK_START)} className="is-info primary-bg">
                        Start new task
                    </Button>
        </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={6}>
          <Card className="card">
            <Card.Header>
              <span className="email-header-title">Out of Stock task processor</span>
            </Card.Header>
            <Card.Body className="card-content">
              { noJob && <Alert variant='secondary'>No task is currently active</Alert> }
              { 
                isFailed &&  
                <Alert variant="danger">
                  <Alert.Heading>Task failed</Alert.Heading>
                  Contact backend team to investigate this and restart task manually.
                </Alert>
              }
              { 
                isCompleted &&  
                <Alert variant="success">
                  <Alert.Heading>Task completed.</Alert.Heading>
                  You can start a new task.
                </Alert>
              }
              {
                isActive &&
                <Alert variant="primary">
                  <Alert.Heading>Task in progress</Alert.Heading>
                  You can't start a new task until this one is completed.
                </Alert>
              }

              { data?.job &&
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Task type</td>
                      <td>{data.job.type}</td>
                    </tr>
                    <tr>
                      <td>Task params</td>
                      <td><pre>{JSON.stringify(data.job.params, null, 2)}</pre></td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{data.job.status}</td>
                    </tr>
                    <tr>
                      <td>Progress</td>
                      <td>{data.job.progress} %</td>
                    </tr>
                    <tr>
                      <td>Error</td>
                      <td>{data.job.error || '-'}</td>
                    </tr>
                  </tbody>
                </Table>
              }

            </Card.Body>
          </Card>

          <Card className="card">
            <Card.Header>
              <span className="email-header-title">Job logs</span>
            </Card.Header>
            <Card.Body className="card-content">
              <pre>
                {data?.job?.logs?.join('\n')}
                {data?.job?.error ? `\n${data?.job.error}` : ''}
              </pre>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </>
  )
}
