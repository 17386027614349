import React from 'react'
import {useHistory} from 'react-router-dom'
import {AppRoutes} from '../../app-routes'
import {AppTableContainer} from '../../components/AppTableContainer'
import {ICoupon} from '../../data-types'

interface Props {
  coupons: ICoupon[]
  loading?: boolean
}

export const CouponsTable: React.FC<Props> = ({loading, coupons}) => {
  const history = useHistory()

  return (
    <AppTableContainer
      label="Coupons"
      header={['Name', 'Full name', 'Discount type', 'Duration Type', 'Redeem count', 'Markets']}
      loading={loading}
      data={coupons}
      renderRow={renderRow}
      getItemKey={c => c.id}
      empty={!loading && !coupons.length}
      onRowClick={c => history.push(AppRoutes.COUPON.getRoute(c.id))}
    />
  )
}

const renderRow = (c: ICoupon) => {
  return (
    <>
      <td>{c.name}</td>
      <td>{c.fullName}</td>
      <td>{c.discountType.label}</td>
      <td>{c.durationType.label}</td>
      <td>{c.redeemCount}</td>
      <td>{c.markets?.join(', ')}</td>
    </>
  )
}
