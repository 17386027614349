import { DefaultOptions } from 'react-query'
import { defaultQueryErrorHandler } from './utils'

export const QueryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    cacheTime: 5000 * 60, // 5 minutes
    staleTime: 5000 * 60, // 5 minutes
    retry: false,
  },
  mutations: {
    onError: defaultQueryErrorHandler,
  },
}
