export const Queries = {
  IMPORTS: 'imports',
  IMPORT: (id: string) => ['imports', id],
  COUPONS: 'coupons',
  COUPON: (id: string) => ['coupons', id],
  COUPON_CREATE_DATA: 'coupon-create-data',
  GIFT_CARDS: 'gift-cards',
  SUBSCRIPTION: (id: string) => ['subscription', id],
  ME: 'me',
  USERS: 'users',
  USER: (id: string) => ['users', id],
  GET_VARIANTS: 'get-addon-variants',
  OUT_OF_STOCK_JOB_STATUS: 'out-of-stock-job-status',
  OUT_OF_STOCK_START_DATA: 'out-of-stock-start-data',
}
