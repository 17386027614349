import React from 'react'

export const ModalContainer: React.FC = ({ children }) => {
  return (
    <>
      <div id="modal-container" />
      {children}
    </>
  )
}
