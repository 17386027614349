import { useEffect, useRef } from 'react'

export function useDebounce(fn: () => void, deps: any[], timeout = 250) {
  const debounce = useRef<any>(null)

  useEffect(() => {
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(fn, timeout)

    return () => clearTimeout(debounce.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps])
}

export function useDebounceFn<T>(fn: T, timeout = 250): T {
  const debounce = useRef<any>(null)

  // @ts-ignore
  return (...params) => {
    if (debounce.current) clearTimeout(debounce.current)

    // @ts-ignore
    debounce.current = setTimeout(() => fn(...params), timeout)
  }
}
