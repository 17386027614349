import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { MdSearch } from 'react-icons/md'
import classNames from 'classnames'

interface Props {
  placeHolder: string
  addonLabel: string
  showIcon?: boolean
  onClick?: (...params: any) => any
  value?: string
  onChange: (val: string) => any
}

export const SearchInput: React.FC<Props> = ({
  value,
  addonLabel,
  placeHolder,
  onChange,
  showIcon = true,
  onClick,
}) => {
  return (
    <InputGroup className="mb-3">
      {showIcon && (
        <InputGroup.Text style={{ backgroundColor: 'white', borderRight: 'none' }} id="basic-addon1">
          <MdSearch />
        </InputGroup.Text>
      )}
      <FormControl
        value={value}
        onChange={e => onChange(e.target.value)}
        className="shadow-none"
        style={{ borderLeft: showIcon ? 'none' : undefined }}
        placeholder={placeHolder}
      />
      <InputGroup.Text
        onClick={onClick}
        className={classNames('primary-bg text-white', {
          pointer: !!onClick,
        })}
        id="basic-addon2"
      >
        {addonLabel}
      </InputGroup.Text>
    </InputGroup>
  )
}
