import { Button, Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getImports } from '../../api'
import { AppRoutes } from '../../app-routes'
import { Queries } from '../../queries'
import { SearchInput } from '../../components/SearchInput'
import { ImportsTable } from './ImportsTable'
import { TablePagination } from '../../components/TablePagination'
import { useState } from 'react'
import { useDebounceFn } from '../../hooks/use-debounce'

const PER_PAGE = 5

export const ImportsScreen = () => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [term, setTerm] = useState('')
  const { data, isLoading } = useQuery([Queries.IMPORTS, term, page], () => getImports(term, page, PER_PAGE))

  const onSearch = useDebounceFn((term: string) => {
    setPage(0)
    setTerm(term)
  })

  return (
    <>
      <Row className="justify-content-between p-2">
        <Col lg={5} md={12} className="ml-1">
          <SearchInput addonLabel="Search" placeHolder="Search order imports" onChange={onSearch} />
        </Col>
        <Col lg={2} md={12} className="mr-4">
          <Row className="justify-content-end flex-shrink-1 flex-grow-0">
            <Button onClick={() => history.push(AppRoutes.IMPORTS_CREATE)} className="is-info primary-bg">
              New import
            </Button>
          </Row>
        </Col>
      </Row>
      <ImportsTable imports={data?.imports ?? []} loading={isLoading} />
      {!isLoading && data && (
        <Row xs={5} className="justify-content-end">
          <TablePagination pageCount={data?.totalCount / PER_PAGE} page={page} onChange={setPage} />
        </Row>
      )}
    </>
  )
}
