import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { createCoupon } from '../../api'
import { AppRoutes } from '../../app-routes'
import { CouponForm } from '../../forms/coupon-form'
import { showToast } from '../../message'
import { Queries } from '../../queries'

export const CreateCouponScreen = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(createCoupon, {
    onSuccess: () => {
      showToast('Created', 'Successfully created coupon')
      queryClient.invalidateQueries([Queries.COUPONS])
      history.push(AppRoutes.COUPONS)
    },
  })

  return (
    <div className="container create-import-view">
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Create a new coupon</p>
            </header>
            <div className="card-content">
              <div className="content">
                <CouponForm onSubmit={mutateAsync} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
