import {axios} from './axios'
import {
  CouponCreateDataResponse,
  GetSubscriptionResponse,
  ICoupon,
  ICouponsResponse,
  IGiftCardsResponse,
  IImportResponse,
  IImportsResponse,
  ILoginResponse,
  IUser,
  IUsersResponse,
  IOutOfStockJobStatus,
  IOutOfStockStartData
} from './data-types'
import {
  CouponFormType,
  ImportFormType,
  LoginFormType,
  OutOfStockStartFormType,
  ResendOrderFormType, UpdateCouponFormType,
  UserFormType
} from './forms/schemas'

export async function getImports(term?: string, page = 0, limit = 100) {
  const { data } = await axios.get<IImportsResponse>('admin/imports', { params: { term, page, limit } })

  return data
}

export async function getImport(id: EntityId) {
  const { data } = await axios.get<IImportResponse>(`admin/imports/${id}`)

  return data
}

export async function getCoupons(filters = {}, page = 0, limit = 100) {
  const { data } = await axios.get<ICouponsResponse>('admin/coupons', { params: { page, limit, ...filters } })

  return data
}

export async function getCoupon(couponId: EntityId) {
  const { data } = await axios.get<ICoupon>(`admin/coupons/${couponId}`)

  return data
}

export async function getCouponCreateData() {
  const { data } = await axios.get<CouponCreateDataResponse>('admin/coupons/api/create-data')

  return data
}

export async function createCoupon(form: CouponFormType) {
  const {file, ...rest} = form;

  if (file) {
    const formData = new FormData()
    formData.append('import', file)
    const { data } = await axios.post('admin/coupons/parse-file', formData)
    return (await axios.post('admin/coupons', {...rest, emailsAllowed:data })).data;
  }
  const { data } = await axios.post('admin/coupons', rest)
  return data
}

export async function updateCoupon({ couponId, form }: { couponId: string; form: UpdateCouponFormType }) {
  const {file, ...rest} = form;

  if (file) {
    const formData = new FormData()
    formData.append('import', file)
    const { data } = await axios.post('admin/coupons/parse-file', formData)
    return (await axios.patch(`admin/coupons/${couponId}`, {...rest, emailsAllowed:data })).data;
  }
  const { data } = await axios.patch(`admin/coupons/${couponId}`, rest)
  return data
}

export async function deleteCoupon(couponId: string) {
  const { data } = await axios.delete(`admin/coupons/${couponId}`);
  return data;
}

export async function validateCouponName(name: string): Promise<boolean> {
  const { data } = await axios.post('admin/coupons/validate-name', { name })

  return data?.valid
}

export async function getGiftCards(filters = {}, page = 0, limit = 100) {
  const { data } = await axios.get<IGiftCardsResponse>('admin/gift-cards', { params: { page, limit, ...filters } })

  return data
}

export async function getSubscription(subscriptionId: string) {
  const { data } = await axios.get<GetSubscriptionResponse>(`admin/emails/${subscriptionId}`)

  return data
}

export async function resendOrder({ subscriptionId, form }: { subscriptionId: string; form: ResendOrderFormType }) {
  const { data } = await axios.post('admin/emails/send', { subscriptionId, ...form })

  return data
}

export async function createImport(form: ImportFormType) {
  const { file, importType, note } = form
  const formData = new FormData()
  formData.append('import', file)
  formData.append('importType', importType)
  formData.append('note', note)
  const { data } = await axios.post<{ importEntityId: number }>('free-shipment/imports', formData)

  return data
}

export async function processImport(importId: EntityId) {
  const { data } = await axios.post(`free-shipment/imports/process-import/${importId}`)

  return data
}

export async function replayImport(importId: EntityId) {
  const { data } = await axios.post(`admin/imports/${importId}`)

  return data
}

export async function login(form: LoginFormType) {
  const { data } = await axios.post<ILoginResponse>('admin/auth/login', form)

  return data
}

export async function getMe() {
  const { data } = await axios.get<IUser>('admin/auth/me')

  return data
}

export async function getUsers(term?: string,  page = 0, limit = 100) {
  const { data } = await axios.get<IUsersResponse>('admin/users', { params: { term,  page, limit } })

  return data
}

export async function getUser(id: EntityId) {
  const { data } = await axios.get<IUser>(`admin/users/${id}`)

  return data
}

export async function createUser(form: UserFormType) {
  const { data } = await axios.post<IUser>('admin/users', form)

  return data
}

export async function promoteUser(id: EntityId) {
  const { data } = await axios.put<IUser>(`admin/users/${id}`, { superAdmin: true})

  return data
}

export async function demoteUser(id: EntityId) {
  const { data } = await axios.put<IUser>(`admin/users/${id}`, { superAdmin: false})

  return data
}

export async function updateUser(form: CouponFormType) {
  const { data } = await axios.put<IUser>('admin/users', form)

  return data
}

export async function deleteUser(id: EntityId) {
  const { data } = await axios.delete(`admin/users/${id}`)

  return data
}

export async function getOutOfStockJobStatus() {
  const { data } = await axios.get<IOutOfStockJobStatus>('out-of-stock-processor/product-out-of-stock-job-status', { params: { } })

  return data
}

export async function getOutOfStockStartData() {
  const { data } = await axios.get<IOutOfStockStartData>('out-of-stock-processor/product-out-of-stock-start-data', { params: { } })

  return data
}

export async function removeOutOfStockProductFromSubscriptions(form: OutOfStockStartFormType) {
  const { data } = await axios.post<any>('out-of-stock-processor/remove-product-from-subscriptions', form)

  return data
}
