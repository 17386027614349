import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { getSubscription, resendOrder } from '../api'
import { AppButton } from '../components/AppButton'
import { AppForm, FormButton, FormCheckbox } from '../components/Form'
import { SearchInput } from '../components/SearchInput'
import { GetSubscriptionResponse } from '../data-types'
import { ResendOrderFormType, resendOrderSchema } from '../forms/schemas'
import { useZodForm } from '../hooks/use-zod'
import { showToast } from '../message'
import { Queries } from '../queries'
import { getDataFromAxiosErr } from '../utils'

export const EmailsScreen = () => {
  const [subscriptionId, setSubscriptionId] = useState('')
  const { data, isLoading, refetch, isError, error } = useQuery(
    Queries.SUBSCRIPTION(subscriptionId),
    () => getSubscription(subscriptionId),
    {
      enabled: false,
    }
  )

  const onCancel = () => {
    setSubscriptionId('')
    refetch()
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={6}>
          <Card className="card">
            <Card.Header>
              <span className="email-header-title">Search orders</span>
            </Card.Header>
            <Card.Body className="card-content">
              <SearchInput
                showIcon={false}
                addonLabel="Search"
                value={subscriptionId}
                onChange={setSubscriptionId}
                onClick={() => (subscriptionId ? refetch() : undefined)}
                placeHolder="Enter subscription ID"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={6}>
          {data && <Subscription data={data} onCancel={onCancel} />}
          {isError && <NotFound msg={getDataFromAxiosErr(error as AxiosError)?.message} />}
          {isLoading && (
            <Row className="justify-content-center">
              <Spinner animation="border" />
            </Row>
          )}
        </Col>
      </Row>
    </>
  )
}

const NotFound: React.FC<{ msg: string }> = ({ msg }) => {
  return (
    <Container>
      <div className="notification is-danger">{msg}</div>
    </Container>
  )
}

const Subscription: React.FC<{ data: GetSubscriptionResponse; onCancel: () => any }> = ({ data, onCancel }) => {
  const { id, planId, status, shippingAddress, customer } = data
  const form = useZodForm(resendOrderSchema)
  const { mutateAsync } = useMutation(resendOrder, {
    onSuccess: () => {
      showToast('Success', 'Successfully resent order')
      onCancel()
    },
  })

  const onSubmit = (form: ResendOrderFormType) => mutateAsync({ subscriptionId: id, form })

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">Subscription Info</p>
      </header>
      <div className="card-content">
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <tbody>
              <tr>
                <td>ID:</td>
                <td>{id}</td>
              </tr>
              <tr>
                <td>Plan ID:</td>
                <td>{planId}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>{status}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>Shipping Address:</strong>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table className="table is-fullwidth">
                    <tbody>
                      <tr>
                        <td>Email:</td>
                        <td>{shippingAddress.email}</td>
                      </tr>
                      <tr>
                        <td>Full Name:</td>
                        <td>
                          {shippingAddress.firstName} {shippingAddress.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>Country:</td>
                        <td>{shippingAddress.countryCode}</td>
                      </tr>
                      <tr>
                        <td>City:</td>
                        <td>{shippingAddress.city}</td>
                      </tr>
                      <tr>
                        <td>Street:</td>
                        <td>{shippingAddress.street}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>Customer Info:</strong>
                </td>
              </tr>
              <tr>
                <td>ID:</td>
                <td>{customer.id}</td>
              </tr>
              <tr>
                <td>Full name:</td>
                <td>
                  {customer.firstName} {customer.lastName}
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{customer.email}</td>
              </tr>
              <tr>
                <td>Phone:</td>
                <td>{customer.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <AppForm form={form} onSubmit={onSubmit}>
          <div className="field">
            <div className="control">
              <FormCheckbox label="Resend order confirmation e-mail" name="sendSubscriptionConfirmation" />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <FormCheckbox label="Resend account creation e-mail" name="sendAccountCreated" />
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <FormButton>Send</FormButton>
            </div>
            <div className="control">
              <AppButton variant="link" onClick={() => onCancel()}>
                Cancel
              </AppButton>
            </div>
          </div>
        </AppForm>
      </div>
    </div>
  )
}
