import { Route, Switch } from 'react-router-dom'
import { AppRoutes } from './app-routes'
import { UserContextProvider } from './hooks/user-context'
import { MainLayout } from './layouts/MainLayout'
import { LoginScreen } from './screens/LoginScreen'

export const MainRouter = () => {
  return (
    <UserContextProvider>
      <Switch>
        <Route exact path={AppRoutes.LOGIN} component={LoginScreen} />
        <Route path="/" component={MainLayout} />
      </Switch>
    </UserContextProvider>
  )
}
