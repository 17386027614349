import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { getCoupon, getCouponCreateData, updateCoupon } from '../../api'
import { AppRoutes } from '../../app-routes'
import { showToast } from '../../message'
import { Queries } from '../../queries'
import { UpdateCouponForm } from "../../forms/update-coupon-form";
import { UpdateCouponFormType } from "../../forms/schemas";
import { DataLoadingGuard } from '../../components/DataLoadingGuard'

export const UpdateCouponScreen = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading: isDataLoading } = useQuery(Queries.COUPON(id), () => getCoupon(id))
  const { data: createData, isLoading: isCreateDataLoading } = useQuery(Queries.COUPON_CREATE_DATA, () => getCouponCreateData())
  const isLoading = isDataLoading || isCreateDataLoading;

  const { mutateAsync } = useMutation(updateCoupon, {
    onSuccess: () => {
      showToast('Created', 'Successfully updated coupon')
      queryClient.invalidateQueries([Queries.COUPONS])
      history.push(AppRoutes.COUPONS)
    },
  })

  const onSubmit = (form: UpdateCouponFormType) => mutateAsync({ couponId: id, form })
  const onCancel = () => history.push(AppRoutes.COUPONS)

  return (
    <div className="container create-import-view">
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Edit coupon</p>
            </header>
            <div className="card-content">
              <div className="content">
                {data && createData ?
                  <UpdateCouponForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    data={data}
                    createData={createData}
                  /> :
                  <DataLoadingGuard
                    isLoading={isLoading}
                  ></DataLoadingGuard>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
