import React from 'react'
import { Row, Spinner } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { RouteChildrenProps, useParams } from 'react-router-dom'
import { getImport, replayImport } from '../../api'
import { AppButton } from '../../components/AppButton'
import { IImportResponse } from '../../data-types'
import { Queries } from '../../queries'

export const ImportScreen: React.FC<RouteChildrenProps> = props => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useQuery(Queries.IMPORT(id), () => getImport(id))

  return (
    <>
      <div className="columns">
        <div className="column is-full">
          <div className="card events-card">
            {!isLoading && data && <Content data={data} />}
            {isLoading && (
              <Row className="justify-content-center py-6">
                <Spinner animation="border" />
              </Row>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const Content: React.FC<{ data: IImportResponse }> = ({ data }) => {
  const { mutateAsync, isLoading } = useMutation(replayImport)
  const { freeShipment, canReplay, importEntries } = data
  return (
    <>
      <header className="card-header">
        <p className="card-header-title">Import ID: {freeShipment.id}</p>
      </header>
      <div className="card-table">
        <div className="content">
          <div className="table-container">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Import type</th>
                  <th>Email</th>
                  <th>Full name</th>
                  <th>Country code</th>
                  <th>Address</th>
                  <th>Zip Code</th>
                  <th>City</th>
                  <th>Phone No.</th>
                  <th>Products Names</th>
                  <th>Selected Addons</th>
                  <th>Subscription ID</th>
                  <th>Status</th>
                  <th>Tracking id</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                {importEntries.map(ie => (
                  <tr key={ie.id}>
                    <td>{ie.id}</td>
                    <td>{ie.importType}</td>
                    <td>{ie.email}</td>
                    <td>{ie.fullName}</td>
                    <td>{ie.countryCode}</td>
                    <td>{ie.address}</td>
                    <td>{ie.zipCode}</td>
                    <td>{ie.city}</td>
                    <td>{ie.phoneNumber}</td>
                    <td>{ie.productsNames}</td>
                    <td>{ie.selectedAddonsIds.map((selectedAddonId: string) => `${selectedAddonId}, `)}</td>
                    <td>{ie.subscriptionId}</td>
                    <td>
                      <Status status={ie.status} />
                    </td>
                    <td>{ie.errorMessage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {canReplay && (
        <footer id="js-replay-footer" className="card-footer">
          <div className="card-footer-item">
            <div className="has-text-centered">
              <p className="">This import seems to be stuck. Click replay to re-run.</p>
              <p className="is-size-7">(That will replay only items marked as Done)</p>
            </div>
          </div>
          <div className="card-footer-item">
            <AppButton isLoading={isLoading} onClick={() => mutateAsync(freeShipment.id)} className="button is-warning">
              Replay
            </AppButton>
          </div>
        </footer>
      )}
    </>
  )
}

const Status: React.FC<{ status: number }> = ({ status }) => {
  switch (status) {
    case 0:
      return <span className="tag is-info is-light">Ready</span>
    case 1:
      return <span className="tag is-danger">Error</span>
    case 2:
      return <span className="tag is-success">Done</span>
    case 3:
      return <span className="tag is-link">Skipped</span>
    default:
      return null
  }
}
