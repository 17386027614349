import React, { useCallback, useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getCouponCreateData } from '../api'
import { AppRoutes } from '../app-routes'
import { AppButton } from '../components/AppButton'
import { DataLoadingGuard } from '../components/DataLoadingGuard'
import {
  AppForm,
  AppFormProps, FormBooleanField,
  FormButton,
  FormCheckbox,
  FormDateTimeField, FormFileInput,
  FormInputField,
  FormSelectField,
} from '../components/Form'
import { CouponValueLabel, IMarket, AddonsWithVariants } from '../data-types'
import { useZodForm } from '../hooks/use-zod'
import { Queries } from '../queries'
import { couponFormSchema, CouponFormType } from './schemas'

function formatSubmit(this: any, form: CouponFormType, allMarkets: IMarket[], allAddons: CouponValueLabel[]) {
  const { markets, fixedAmounts, addons, addonVariants, minimumAmount, ...rest } = form

  const formattedMarkets = markets.reduce<string[]>((acc, m, i) => {
    if (m) acc.push(allMarkets[i].id)

    return acc
  }, [])

  const formattedAddons = (addons ?? []).reduce<string[]>((acc, m, i) => {
    if (m) acc.push(allAddons[i].value)

    //Fileter here =) 
    return acc
  }, [])

  const formattedAddonVariants = () => {
    let variants = []
    for (const key in addonVariants) {
      const element = addonVariants[key];
      for (const variantName in element) {
        if (element[variantName]) variants.push(variantName)
      }
    }
    return variants
  }


  const discountAmounts = [];
  for (const [key, value] of Object.entries(fixedAmounts ?? [])) {
    if (formattedMarkets.includes(key)) {
      discountAmounts.push({ marketId: key, amount: Math.round(Number(value) * 100) })
    }
  }

  const minimalAmountsPerMarket = [];
  for (const [key, value] of Object.entries(minimumAmount ?? [])) {
    if (formattedMarkets.includes(key)) {
      minimalAmountsPerMarket.push({ marketId: key, amount: Math.round(Number(value) * 100) })
    }
  }

  const result = {
    ...rest,
    applyOnAddons: (rest.applyOn === 'each_specified_item' && formattedAddons.length) ? formattedAddons : undefined,
    applyOnAddonVariants: (rest.applyOn === 'each_specified_item' && formattedAddonVariants().length) ? formattedAddonVariants() : undefined,
    discountAmounts: rest.discountType === 'fixed_amount' ? discountAmounts : undefined,
    minimumAmount: minimalAmountsPerMarket,
    markets: formattedMarkets,
    period: rest.durationType === 'limited_period' ? rest.period : undefined,
    periodUnit: rest.durationType === 'limited_period' ? rest.periodUnit : undefined,
    discountPercentage: rest.discountType === 'percentage' ? rest.discountPercentage : undefined,
    applyOn: rest.discountType === 'free_product' ? 'invoice_amount' : rest.applyOn,
    durationType: (rest.discountType === 'free_product' || rest.applyOn === 'each_specified_item' || rest.requiredAddon) ? 'one_time' : rest.durationType,
    freeProductAddonGroup: rest.discountType === 'free_product' ? rest.freeProductAddonGroup : undefined,
    freeAddonVariant: (rest.discountType === 'free_product' && rest.freeAddonVariant) ? rest.freeAddonVariant : 'all'
  }
  return result
}

function getFormattedMarkets(chosenMarkets: boolean[] | undefined, allMarkets: IMarket[]) {

  if (chosenMarkets === undefined) {
    return allMarkets;
  }

  return chosenMarkets.reduce<IMarket[]>((acc, m, i) => {
    if (m) acc.push(allMarkets[i])

    return acc
  }, [])
}

export const CouponForm: React.FC<AppFormProps<any>> = ({ onSubmit }) => {
  const history = useHistory()
  const form = useZodForm(couponFormSchema)
  const [addonsVariants, setAddonsVariants] = useState<(AddonsWithVariants | undefined)[]>([]);
  const { data, isLoading } = useQuery(Queries.COUPON_CREATE_DATA, () => getCouponCreateData())
  const discountType = form.watch('discountType')
  const durationType = form.watch('durationType')
  const requiredAddon = form.watch('requiredAddon')
  const applyOn = form.watch('applyOn')
  const chosenMarkets = form.watch('markets');
  const freeProductAddonGroup = form.watch('freeProductAddonGroup')
  const choosedAddons = useWatch({ control: form.control, name: 'addons' })

  const getAddonVariants = () => {
    if (discountType === 'free_product' && freeProductAddonGroup) {
      let variants = data?.variants.filter((a) => a.value.includes(freeProductAddonGroup)) ?? []
      if (variants.length > 1)
        return [{ value: 'all', label: 'All' }, ...variants]
    }
    return []
  }

  const getRequiredAddonVariants = () => {
    if (requiredAddon) {
      let variants = data?.variants.filter((a) => a.value.includes(requiredAddon)) ?? []
      if (variants.length > 1)
        return [{ value: 'all', label: 'All' }, ...variants]
    }
    return []
  }


  const getChoosedAddons = useCallback(() => {
    if (!choosedAddons) return
    const selectedAddons = data?.addonGroups?.map((option, index) => !!choosedAddons[index] && option)
    let listOfItems: (AddonsWithVariants | undefined)[] = []
    selectedAddons?.forEach((addon) => {
      if (addon) {
        let variants = data?.variants.filter((variant) => variant.value?.includes(addon.value))
        listOfItems.push({
          addon: { ...addon },
          variants,
          numberOfVariants: variants?.length || 0
        })
      } else {
        listOfItems.push(undefined)
      }
    })
    setAddonsVariants(listOfItems)
  }, [setAddonsVariants, data, choosedAddons])

  useEffect(() => {
    //@ts-ignore
    getChoosedAddons(choosedAddons)
  }, [getChoosedAddons, choosedAddons])

  return (
    <AppForm onSubmit={form => onSubmit(formatSubmit(form, data?.markets ?? [], data?.addonGroups ?? []))}
      form={form}>
      <div className="box">
        <FormInputField
          label="Coupon code*"
          text={
            <>Coupon code needs to be upper-cased without spacing</>
          }
          name="name"
        />

        <div className="mt-4">
          <FormInputField label="Influencers Name" name="fullName" />
        </div>

        <div className="mt-4">
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Team*"
              name="team"
              options={data?.team ?? []}
              placeholder="Select team"
            />
          </DataLoadingGuard>
        </div>
        <div className="mt-4">
          <FormInputField label="Purpose*" name="invoiceName" />
        </div>

        <div className="field block mt-4">
          <label className="label">Markets</label>
          <DataLoadingGuard isLoading={isLoading}>
            {data?.markets.map((m, i) => (
              <FormCheckbox key={m.id} label={m.name} name={`markets[${i}]`} defaultValue={true} />
            ))}
          </DataLoadingGuard>
        </div>
      </div>

      <div className="box">
        <div className="field block">
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Coupon type*"
              name="discountType"
              options={data?.discountTypes ?? []}
              placeholder="Select coupon type"
            />
          </DataLoadingGuard>
        </div>
        {discountType === 'percentage' && (
          <FormInputField type="number" name="discountPercentage" label="Discount percentage*" min={0} />
        )}
        {discountType === 'fixed_amount' && (
          <div className="field block mt-4">
            <label className="label">Discount amounts*</label>
            <DataLoadingGuard isLoading={isLoading}>
              {getFormattedMarkets(chosenMarkets, data?.markets ?? []).map((m) => (
                <FormInputField key={m.id} label={m.name} text={m.currencyCode}
                  name={`fixedAmounts[${m.id}]`} type="number" step={0.01}
                  defaultValue={0} min={0} />
              ))}
            </DataLoadingGuard>
          </div>
        )}
        {discountType === 'free_product' && (
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Free product*"
              name="freeProductAddonGroup"
              options={data?.addonGroups ?? []}
              placeholder="Select free product"
            />
          </DataLoadingGuard>
        )}
        {discountType === 'free_product' && freeProductAddonGroup && getAddonVariants().length > 1 && (
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Free Variant*"
              name="freeAddonVariant"
              options={getAddonVariants()}
              placeholder="Select product variant"
            />
          </DataLoadingGuard>
        )}

      </div>

      {discountType !== 'free_product' && (
        <>
          <div className="box">
            <div className="field block">
              <DataLoadingGuard isLoading={isLoading}>
                <FormSelectField
                  label="Apply coupon*"
                  name="applyOn"
                  options={data?.applyOn ?? []}
                  placeholder="Select coupon apply on"
                />
              </DataLoadingGuard>
            </div>
            {applyOn === 'each_specified_item' && (
              <DataLoadingGuard isLoading={isLoading}>
                {data?.addonGroups.map((a, i) => (
                  <FormCheckbox key={a.value} label={a.label} name={`addons[${i}]`} defaultValue={false} />
                ))}
              </DataLoadingGuard>
            )}
          </div>
          <>
            {addonsVariants.length > 0 && (
              addonsVariants.filter(a => !!a).map((addon, i) => {
                if ((addon?.numberOfVariants || 0) > 1) {
                  return (
                    <div className="box" key={addon?.addon.value}>
                      <label className="label">{addon?.addon?.label}</label>
                      {applyOn === 'each_specified_item' && (
                        <DataLoadingGuard isLoading={isLoading}>
                          {(addon?.variants || []).map((a) => (
                            <FormCheckbox key={a.value} label={a.label} name={`addonVariants[${addon?.addon.value}][${a.value}]`} defaultValue={false} />
                          ))}
                        </DataLoadingGuard>
                      )}
                    </div>
                  )
                } else { return '' }
              })
            )}
          </>

        </>
      )}

      <div className="box">
        <DataLoadingGuard isLoading={isLoading}>
          <FormSelectField
            label="Required product in cart"
            name="requiredAddon"
            options={data?.addonGroups ?? []}
            placeholder="Choose addon"
          />
        </DataLoadingGuard>
        <br />
        {requiredAddon && getRequiredAddonVariants().length > 1 && (
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Required addon variant"
              name="requiredAddonVariant"
              options={getRequiredAddonVariants()}
              placeholder="Select required addon variant"
            />
          </DataLoadingGuard>
        )}
      </div>

      {discountType !== 'free_product' && applyOn !== 'each_specified_item' && !requiredAddon && (
        <div className="box">
          <div className="field block">
            <DataLoadingGuard isLoading={isLoading}>
              <FormSelectField
                label="Duration type*"
                name="durationType"
                options={data?.durationTypes ?? []}
                placeholder="Select duration type"
                text="How long should this coupon be applied to the subscription."
              />
            </DataLoadingGuard>
          </div>
          {durationType === 'limited_period' && (
            <div className="field block">
              <FormInputField type="number" name="period" label="Period" />
              <DataLoadingGuard isLoading={isLoading}>
                <FormSelectField
                  label="Period unit"
                  name="periodUnit"
                  options={data?.periodUnits ?? []}
                  placeholder="Select period unit"
                />
              </DataLoadingGuard>
            </div>
          )}
        </div>
      )}

      <div className="box">
        <div className="block">
          <FormDateTimeField
            label="Valid from"
            name="validFrom"
            text="Date and time (CEST) upto which the coupon can be applied to new subscriptions."
          />
        </div>
        <div className="block">
          <FormDateTimeField
            label="Valid till*"
            name="validUntil"
            text="Date and time (CEST) upto which the coupon can be applied to new subscriptions."
          />
        </div>
      </div>

      <div className="box">
        <div className="mt-4">
          <FormInputField
            label="Total redemptions"
            name="maxRedemptions"
            type="number"
            text="Maximum number of times this coupon can be redeemed."
            min={1}
          />
        </div>

        <div className="mt-4">
          <FormInputField
            label="Total redemptions per user"
            name="maxRedemptionsPerUser"
            type="number"
            text="Maximum number of times this coupon can be redeemed per user."
            min={1}
          />
        </div>
      </div>

      <div className="box">

        <FormBooleanField
          label="Valid for subscription purchases only (If checked, the coupon will not work for one-offs or standalones)."
          name="subscriptionRequired"
          defaultValue={false}
        />
      </div>

      <div className="box">
        <div className="mt-4">
          <DataLoadingGuard isLoading={isLoading}>
            <FormSelectField
              label="Can be used by"
              name="canBeUsedBy"
              options={data?.canBeUsedByOptions ?? []}
              defaultValue={data?.defaultCanBeUsedByOption}
            />
          </DataLoadingGuard>
        </div>
      </div>

      <div className="box">
        <div className="block">
          <label className="label">Minimum amount required</label>
          <DataLoadingGuard isLoading={isLoading}>
            {getFormattedMarkets(chosenMarkets, data?.markets ?? []).map((m, i) => (
              <FormInputField key={m.id} label={m.name} text={m.currencyCode}
                name={`minimumAmount[${m.id}]`} type="number" step={0.01} defaultValue={0} min={0} />
            ))}
          </DataLoadingGuard>
        </div>
      </div>

      <div className="box">
        <div className="field">
          <FormFileInput name="file" label="Customer emails that coupons will apply to" accept=".csv" />
        </div>
      </div>

      <div className="d-flex">
        <FormButton>Create</FormButton>
        <div className="ml-2">
          <AppButton variant="light" onClick={() => history.push(AppRoutes.COUPONS)}>
            Cancel
          </AppButton>
        </div>
      </div>
    </AppForm>
  )
}
