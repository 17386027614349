import { z } from 'zod'
import { validateCouponName } from '../api'
import { debouncePromise } from '../utils'

const throttledCheck = debouncePromise(async (val: string) => {
  return await validateCouponName(val)
}, 500)

export const couponFormSchema = z
  .object({
    name: z
      .string()
      .nonempty({ message: 'Name is required' })
      .refine(val => !!val, 'Required')
      .refine(throttledCheck, 'Coupon name already taken'),
    fullName: z.string(),
    team: z.string().nonempty({ message: 'Team is required' }),
    invoiceName: z.string().nonempty({ message: 'Purpose is required' }),
    markets: z.array(z.boolean()),
    discountType: z.string().nonempty({ message: 'Coupon type is required' }),
    discountPercentage: z.string().transform(parseInt).optional(),
    fixedAmounts: z.any().optional(),
    freeProductAddonGroup: z.string().optional(),
    freeAddonVariant: z.string().optional(),
    durationType: z.string(),
    productVariant: z.string().optional(),
    applyOn: z.string(),
    addons: z.array(z.boolean()).optional(),
    addonVariants: z.any().optional(),
    period: z.string().transform(parseInt).optional(),
    periodUnit: z.string().optional(),
    validFrom: z.string().transform(val => new Date(val).getTime()),
    validUntil: z.string().refine(val => !!val, 'Required').transform(val => new Date(val).getTime()),
    maxRedemptions: z.string().transform(parseInt).optional()
      .refine(val => ((!val && val !== 0) || val > 0), { message: 'Must be a number greater than 0 ' }),
    maxRedemptionsPerUser: z.string().transform(parseInt).optional()
      .refine(val => ((!val && val !== 0) || val > 0), { message: 'Must be a number greater than 0' }),
    subscriptionRequired: z.boolean(),
    canBeUsedBy: z.string().optional(),
    minimumAmount: z.any().optional(),
    requiredAddon: z.string(),
    requiredAddonVariant: z.string().optional(),
    file: z
      .instanceof(FileList)
      .optional()
      .transform(f => f ? f[0] : undefined)
      .refine(f => (f === undefined) || f.type.includes('csv'), 'Only csv files are allowed'),
  })
  .refine(
    val =>
      val.discountType !== 'percentage' ||
      (!!val.discountPercentage &&
        !isNaN(val.discountPercentage) &&
        val.discountPercentage > 0 &&
        val.discountPercentage <= 100),
    { message: 'Must be a number between 1 and 100', path: ['discountPercentage'] }
  )
  .refine(
    val =>
      val.durationType !== 'limited_period' || (val.durationType === 'limited_period' && val.period),
    { message: 'Must be present', path: ['period'] }
  )
  .refine(
    val =>
      val.durationType !== 'limited_period' || (val.durationType === 'limited_period' && val.periodUnit),
    { message: 'Must be selected', path: ['periodUnit'] }
  )
  .refine(
    val =>
      val.discountType === 'free_product' || (val.discountType !== 'free_product' && val.applyOn),
    { message: 'Apply on is required', path: ['applyOn'] }
  )
  .refine(
    val =>
      val.discountType === 'free_product' || val.applyOn === 'each_specified_item' || val.requiredAddon || (val.discountType !== 'free_product' && val.applyOn !== 'each_specified_item' && !val.requiredAddon && val.durationType),
    { message: 'Duration type is required', path: ['durationType'] }
  )
  .refine(
    val =>
      val.discountType !== 'free_product' || (val.discountType === 'free_product' && val.freeProductAddonGroup),
    { message: 'Free product is required', path: ['freeProductAddonGroup'] }
  )

export type CouponFormType = z.infer<typeof couponFormSchema>

export const updateCouponFormSchema = z
  .object({
    name: z.string(),
    fullName: z.string().nullable().optional(),
    team: z.string().nonempty({ message: 'Team is required' }),
    invoiceName: z.string().nonempty({ message: 'Purpose is required' }),
    markets: z.array(z.string()),
    discountType: z.string().nonempty({ message: 'Coupon type is required' }),
    discountPercentage: z.number().nullable().optional(),
    discountAmounts: z.array(z.object({
      marketId: z.string().nonempty(),
      amount: z.number(),
    })).nullable().optional(),
    freeProductAddonGroup: z.string().optional(),
    freeAddonVariant: z.string().optional(),
    applyOn: z.string().nonempty({ message: 'Apply on is required' }),
    applyOnAddons: z.array(z.string()).optional(),
    applyOnAddonVariants: z.array(z.string()).optional(),
    validFrom: z.string().transform(val => new Date(val).getTime()),
    validUntil: z.string().refine(val => !!val, 'Required').transform(val => new Date(val).getTime()),
    maxRedemptions: z.string().transform(parseInt).optional()
      .refine(val => ((!val && val !== 0) || val > 0), { message: 'Must be a number greater than 0' }),
    maxRedemptionsPerUser: z.string().transform(parseInt).optional()
      .refine(val => ((!val && val !== 0) || val > 0), { message: 'Must be a number greater than 0' }),
    subscriptionRequired: z.boolean(),
    canBeUsedBy: z.string().optional(),
    requiredAddon: z.string(),
    requiredAddonVariant: z.string().optional(),
    durationType: z.string().nonempty({ message: 'Duration type is required' }),
    period: z.number().nullable().optional(),
    periodUnit: z.string().nullable().optional(),
    minimumAmount: z.array(z.object({
      marketId: z.string().nonempty(),
      amount: z.number()
    })).optional(),

    file: z
      .instanceof(FileList)
      .optional()
      .transform(f => f ? f[0] : undefined)
      .refine(f => (f === undefined) || f.type.includes('csv'), 'Only csv files are allowed'),
  })
  .refine(
    val =>
      val.discountType !== 'percentage' ||
      (!!val.discountPercentage &&
        !isNaN(val.discountPercentage) &&
        val.discountPercentage > 0 &&
        val.discountPercentage <= 100),
    { message: 'Must be a number between 1 and 100', path: ['discountPercentage'] }
  )
  .refine(
    val =>
      val.durationType !== 'limited_period' || (val.durationType === 'limited_period' && val.period),
    { message: 'Must be present', path: ['period'] }
  )
  .refine(
    val =>
      val.durationType !== 'limited_period' || (val.durationType === 'limited_period' && val.periodUnit),
    { message: 'Must be selected', path: ['periodUnit'] }
  )
  .refine(
    val =>
      val.discountType !== 'free_product' || (val.discountType === 'free_product' && val.freeProductAddonGroup),
    { message: 'Free product is required', path: ['freeProductAddonGroup'] }
  )

export type UpdateCouponFormType = z.infer<typeof updateCouponFormSchema>

export const resendOrderSchema = z.object({
  sendSubscriptionConfirmation: z.boolean().refine(arg => arg, 'Must be checked'),
  sendAccountCreated: z.boolean(),
})
export type ResendOrderFormType = z.infer<typeof resendOrderSchema>

export const importFormSchema = z.object({
  file: z
    .instanceof(FileList)
    .refine(f => {
      return f && f.length === 1
    }, 'File is required')
    .transform(f => f[0])
    .refine(f => f.type.includes('csv'), 'Only csv files are allowed'),
  importType: z.string().nonempty('Import type is required'),
  note: z.string(),
})
export type ImportFormType = z.infer<typeof importFormSchema>

export const LoginFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})
export type LoginFormType = z.infer<typeof LoginFormSchema>

export const userFormSchema = z
  .object({
    email: z
      .union([z.string(), z.undefined()])
      .refine(val => !val || z.string().email().safeParse(val).success, 'Must be a valid email'),
    password: z.string().nonempty({ message: 'Password is required' }),
    superAdmin: z.boolean(),
  })

export type UserFormType = z.infer<typeof userFormSchema>

export const outOfStockStartFormSchema = z
  .object({
    marketId: z.string().nonempty({ message: 'Market is is required' }),
    productSku: z.string().nonempty({ message: 'Product is required' }),
    customerIds: z.string().optional(),
    dryRun: z.boolean(),
  })

export type OutOfStockStartFormType = z.infer<typeof outOfStockStartFormSchema>