import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { createUser } from '../../api'
import { AppRoutes } from '../../app-routes'
import { showToast } from '../../message'
import { UserForm } from '../../forms/user-form';

export const CreateUserScreen = () => {
  const history = useHistory()

  const { mutateAsync } = useMutation(createUser, {
    onSuccess: () => {
      showToast('Created', 'Successfully created a user')
      history.push(AppRoutes.USERS)
    },
  })

  return (
    <div className="container create-import-view">
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Create a new user</p>
            </header>
            <div className="card-content">
              <div className="content">
                <UserForm onSubmit={mutateAsync} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
