import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app-routes'
import { AppButton } from '../components/AppButton'
import {
  AppForm,
  AppFormProps,
  FormButton,
  FormCheckbox,
  FormInputField,
} from '../components/Form'
import { useZodForm } from '../hooks/use-zod'
import { userFormSchema } from './schemas'

export const UserForm: React.FC<AppFormProps<any>> = ({ onSubmit }) => {
  const history = useHistory()
  const form = useZodForm(userFormSchema)

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <div className="box">

        <div className="mt-4">
          <FormInputField label="Email" name="email" text="Users email" />
        </div>

        <div className="mt-4">
          <FormInputField label="Password" name="password" text="Password" />
        </div>

        <div className="mt-4">
          <FormCheckbox label="Super admin" name="superAdmin" />
        </div>

      </div>

      <div className="d-flex">
        <FormButton>Create</FormButton>
        <div className="ml-2">
          <AppButton variant="light" onClick={() => history.push(AppRoutes.USERS)}>
            Cancel
          </AppButton>
        </div>
      </div>
    </AppForm>
  )
}
