import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { createImport } from '../../api'
import { AppRoutes } from '../../app-routes'
import { ICsvImportError } from '../../data-types'
import { ImportForm } from '../../forms/import-form'
import { ImportFormType } from '../../forms/schemas'
import { Queries } from '../../queries'
import { getDataFromAxiosErr } from '../../utils'

export const CreateImportScreen = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutateAsync: createImportMutation, error: createError, isError: isCreateError } = useMutation(createImport)

  const onSubmit = async (form: ImportFormType) => {
    try {
      const { importEntityId } = await createImportMutation(form)
      queryClient.invalidateQueries(Queries.IMPORTS)
      history.push(AppRoutes.IMPORT.getRoute(importEntityId))
    } catch (e) {}
  }

  return (
    <div className="create-import-view">
      <div className="columns is-centered">
        <div className="column is-6">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Create new import</p>
            </header>
            <ImportForm onSubmit={onSubmit} />
          </div>
        </div>
      </div>
      {isCreateError && <ImportErrorCard errors={getDataFromAxiosErr(createError).validations} />}
    </div>
  )
}

interface ErrorCardProps {
  errors: ICsvImportError[]
}

const ImportErrorCard: React.FC<ErrorCardProps> = ({ errors }) => {
  return (
    <div className="columns is-centered">
      <div className="column is-6">
        <div className="notification is-danger">
          {errors.map((e, i) => (
            <div className="p-d notification is-danger is-light" key={i}>
              <span>Errors at row {e.offset}</span>
              <div className="pl-4">
                {Object.entries(e.errors).map(([key, val], i) => (
                  <div key={i}>
                    <span>{key}: </span>
                    <span>{val}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

// const ProcessErrorCard: React.FC = () => {}
